import React from 'react'
import BannerInner from './BannerInner/BannerInner'
import { Link } from 'react-router-dom'
import OurCourses from './HomePage/OurCourses'

const TermsConditions = () => {
    return (
        <>
            <BannerInner
                title="WebPatches Terms & Conditions"
                banner_desc="Terms & Conditions is a legal agreement outlining the rules and guidelines governing the use of a website or service. It specifies user responsibilities, content ownership, privacy policies, and dispute resolution methods. Users are typically required to accept these terms before using the platform, ensuring a clear understanding of their rights and obligations."
            />
            <section className='comman_tb_padding'>
                <div className='container'>
                    <div className='content_style_comman'>
                        <h2>Our Terms & Conditions</h2>
                        <p>Welcome to WebPatches. These Terms & Conditions govern your use of <Link to="https://webpatches.com/">https://webpatches.com/</Link>. When you access or utilize our Website, you are consenting to adhere to and be legally bound by these Terms & Conditions. If you do not concur with any portion of these terms, we kindly request that you refrain from using our Website.</p>

                        <h2>User Agreement</h2>
                        <p>Through the utilization of our Website, you are in acceptance of the subsequent terms:</p>
                        <ol>
                            <li>
                                <h3>Use of Website</h3>
                                <ul>
                                    <li>You are required to utilize this Website exclusively for lawful purposes.</li>
                                    <li>You are responsible for any content you contribute to the Website.</li>
                                    <li>Engaging in activities that disrupt or interfere with the normal operation of the Website is prohibited.</li>
                                </ul>
                            </li>
                            <li>
                                <h3>User Content</h3>
                                <ul>
                                    <li>You may contribute content to the Website, such as comments, posts, or other materials.</li>
                                    <li>You retain ownership of your content but grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your content.</li>
                                    <li>You are responsible for the content you contribute, and it must not violate our guidelines or any applicable laws.</li>
                                </ul>
                            </li>
                            <li>
                                <h3>Privacy Policy</h3>
                                <p>Your utilization of the Website is also subject to our Privacy Policy, which we encourage you to examine for insights into the collection, usage, and safeguarding of your personal information.</p>
                            </li>
                            <li>
                                <h3>Third-Party Links</h3>
                                <p>The Website might feature connections to external third-party websites or resources. We do not bear responsibility for the content or methodologies of these third parties. You access them based on your own judgment and at your own risk.</p>
                            </li>
                            <li>
                                <h3>Modifications</h3>
                                <p>We reserve the right to modify or terminate the Website or these Terms & Conditions at any time. Any modifications will take effect upon being posted on this page.</p>
                            </li>
                        </ol>

                        <h3>Disclaimer</h3>
                        <p>The content provided on our Website, including study materials and design tools, is for educational and skill development purposes only. We cannot assure the accuracy or entirety of this content.</p>
                        <p>We are not responsible for any actions taken based on the information or tools provided on the Website.</p>
                        {/* <h3>Contact Us</h3>
                    <p>If you have questions, concerns, or requests related to this Privacy Policy or your personal information, please contact us at [Your Contact Information].</p> */}
                    </div>
                </div>
            </section>
            {/*  */}
            <OurCourses />
        </>
    )
}

export default TermsConditions