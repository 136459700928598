import React from 'react'
import BannerInner from '../BannerInner/BannerInner'
import CssCourseSideNav from './CssCourseLayout/CssCourseSideNav'
import CodeHighlightComp from '../Pulgins/CodeHighlight/CodeHighlightComp'
import OurCourses from '../HomePage/OurCourses'

const IncludeCssInHtml = () => {
    return (
        <>

            <BannerInner
                title="How do you include CSS in an HTML web page?"
                banner_desc="Choose the CSS inclusion method that best suits your project's size and complexity. "
            />
            <section className='comman_tb_padding'>
                <div className='container'>
                    <div className='row responsive_row'>
                        <div className='col-lg-3 col-md-4 sideNavbar_comman_main'>
                            <div className='sideNavbar_comman'>
                                <CssCourseSideNav />
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8 content_style_main'>
                            <div className='content_style_comman'>
                                <CodeHighlightComp />
                                <h3>You can include CSS in an HTML document using one of the following methods:</h3>
                                <p>You can center an element both horizontally and vertically in CSS using a variety of techniques. Here are three commonly used methods:</p>
                                <ol>
                                    <li>
                                        <h3><strong>Inline CSS:</strong></h3>
                                        <p>Inline CSS is added directly to individual HTML elements using the style attribute. This method is useful for applying unique styles to specific elements.</p>
                                        <div className='code_highlight_area'>
                                            <div className='code_highlight_header'>
                                                <h5>HTML</h5>
                                            </div>
                                            <pre>
                                                <code className="javascript">
                                                    {`
<p style="color: blue; font-size: 16px;">This is a blue and 16px font size text.</p>
`}
                                                </code>
                                            </pre>
                                        </div>
                                    </li>
                                    <li>
                                        <h3>Internal CSS:</h3>
                                        <p>Internal CSS is defined within a <code>&lt;style&gt;</code> element in the <code>&lt;head&gt;</code> section of the HTML document. This method allows you to apply styles to multiple elements on the same page.</p>
                                        <div className='code_highlight_area'>
                                            <div className='code_highlight_header'>
                                                <h5>HTML</h5>
                                            </div>
                                            <pre>
                                                <code className="javascript">
                                                    {`
<!DOCTYPE html>
<html>
<head>
  <style>
    p {
      color: red;
      font-size: 18px;
    }
  </style>
</head>
<body>
  <p>This is a red and 18px font size text.</p>
</body>
</html>
`}
                                                </code>
                                            </pre>
                                        </div>
                                    </li>
                                    <li>
                                        <h3>External CSS:</h3>
                                        <p>External CSS is the most commonly used method for separating style from content. You create a separate .css file and link it to your HTML document using the <code>&lt;link&gt;</code> element. This method is ideal for maintaining a consistent style across multiple web pages.</p>
                                        <div className='code_highlight_area'>
                                            <div className='code_highlight_header'>
                                                <h5>HTML</h5>
                                            </div>
                                            <pre>
                                                <code className="javascript">
                                                    {`
<!DOCTYPE html>
<html>
<head>
  <link rel="stylesheet" type="text/css" href="styles.css">
</head>
<body>
  <p class="blue-text">This is a blue-text paragraph.</p>
</body>
</html>
`}
                                                </code>
                                            </pre>
                                        </div>
                                        <div className='code_highlight_area'>
                                            <div className='code_highlight_header'>
                                                <h5>CSS</h5>
                                            </div>
                                            <pre>
                                                <code className="javascript">
                                                    {`
.blue-text {
    color: blue;
    font-size: 16px;
  }
`}
                                                </code>
                                            </pre>
                                        </div>
                                    </li>

                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  */}
            <OurCourses />

        </>
    )
}

export default IncludeCssInHtml