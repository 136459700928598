export const data = [
    {
        name: "Icon Finder",
        url: '/resources/icons'
    },
    {
        name: "Image to Fonts Finder",
        url: '/resources/fonts-finder'
    },
    {
        name: "Font Converter",
        url: '/resources/font-converter'
    },
    {
        name: "Designing Tools",
        url: '/resources/designing-tools'
    },
    {
        name: "Fonts",
        url: '/resources/fonts'
    },
    {
        name: "Graphics and Vector",
        url: '/resources/graphics-and-vector'
    },

]