import React from 'react'
import BannerInner from '../BannerInner/BannerInner'
import InterviewQuesSideNav from './InterviewQuesLayout/InterviewQuesSideNav'
import OurCourses from '../HomePage/OurCourses'
import CodeHighlightComp from '../Pulgins/CodeHighlight/CodeHighlightComp'

const CssInterview = () => {
    return (
        <>
            <>
                <BannerInner
                    title="Top CSS Interview Queries"
                    banner_desc="Highly Effective CSS Interview Questions Posed by Leading Companies "
                />
                <section className='comman_tb_padding'>
                    <div className='container'>
                        <div className='row responsive_row'>
                            <div className='col-lg-3 col-md-4 sideNavbar_comman_main'>
                                <div className='sideNavbar_comman'>
                                    <InterviewQuesSideNav />
                                </div>
                            </div>
                            <div className='col-lg-9 col-md-8 content_style_main'>
                                <div className='content_style_comman'>
                                <CodeHighlightComp />
                                    <h2>Best CSS Interview question with Answer.</h2>

                                    <ol>
                                        <li>
                                            <h3>What benefits does CSS offer?</h3>
                                            <ul>
                                                <li>
                                                    <p><strong>Separation of Concerns:</strong> CSS allows for the separation of content (HTML) from presentation (styling). This separation makes it easier to maintain and update a website because changes to the design can be made without altering the underlying HTML structure.</p>
                                                </li>
                                                <li>
                                                    <p><strong>Consistency:</strong> CSS enables consistent styling across an entire website. Design elements like fonts, colors, and spacing can be defined once and applied consistently to all relevant elements, ensuring a cohesive look and feel.</p>
                                                </li>
                                                <li>
                                                    <p><strong>Faster Loading Times:</strong> By externalizing styles into separate CSS files, browsers can cache these files, reducing the need to re-download styling information on subsequent page visits. This leads to faster page loading times and improved website performance.</p>
                                                </li>
                                                <li>
                                                    <p><strong>Responsive Design:</strong> CSS supports responsive web design, allowing developers to create layouts that adapt to different screen sizes and devices. This is essential for providing a good user experience on both desktop and mobile devices.</p>
                                                </li>
                                                <li>
                                                    <p><strong>Compatibility:</strong> CSS is supported by all modern web browsers, making it a reliable and widely used technology for web styling.</p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>What is different position in CSS</h3>
                                            <ul>
                                                <li>
                                                    <p><strong>Static (Default):</strong> Elements with a <code>position</code> value of <code>static</code> are positioned according to the normal flow of the document.</p>
                                                </li>
                                                <li>
                                                    <p><strong>Relative:</strong> When an element is set to <code>position: relative</code>, it is positioned relative to its normal position in the document flow. You can use the <code>top</code>, <code>right</code>, <code>bottom</code>, and <code>left</code> properties to offset it from its original position without affecting the layout of surrounding elements.</p>
                                                </li>
                                                <li>
                                                    <p><strong>Absolute:</strong> Elements with <code>position: absolute</code> are positioned relative to the nearest positioned (non-static) ancestor. If no such ancestor exists, it is positioned relative to the initial containing block. </p>
                                                </li>
                                                <li>
                                                    <p><strong>Fixed:</strong> Fixed position even when the page is scrolled. This is commonly used for elements like navigation bars or banners that need to stay in view as the user scrolls.</p>
                                                </li>
                                                <li>
                                                    <p><strong>Sticky:</strong> Elements with <code>position: sticky</code> are initially in the normal document flow but become "sticky" and stay fixed at a specified position once they reach a defined scroll point. </p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>What is difference between PX, %, em, rem in css</h3>
                                            <ul>
                                                <li>
                                                    <p><strong>px (Pixels):</strong> Pixels are a fixed unit of measurement that represent the smallest unit of screen display
                                                    </p>
                                                </li>
                                                <li>
                                                    <p><strong>% (Percentage):</strong> Percentages are often used for responsive design, as they adapt to the parent element's size.</p>
                                                </li>
                                                <li>
                                                    <p><strong>em:</strong> Em units are useful for creating </p>
                                                </li>
                                                <li>
                                                    <p><strong>rem (Root Em):</strong> It provides a consistent and predictable way to set sizes that are not influenced by nested elements' font sizes.</p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>Why we using box-sizing in css</h3>
                                            <p>The <code>box-sizing</code> property is used to control how the total width and height of an element are calculated, especially when it comes to adding padding and borders. </p>
                                        </li>
                                        <li>
                                            <h3>What is pseudo selector in css</h3>
                                            <p> Pseudo-selectors are used in CSS rules to target elements based on criteria that cannot be targeted using regular selectors alone. Pseudo-selectors are denoted by a colon (<code>:</code>) followed by the keyword. Here are some common pseudo-selectors in CSS:</p>
                                            <ul>
                                                <li>
                                                    <p><strong><code>:hover</code>:</strong> Targets an element when a user hovers their mouse pointer over it. </p>
                                                </li>
                                                <li>
                                                    <p><strong><code>:active</code>:</strong> Targets an element when it is in an active state, such as when a user clicks on it. It's often used for styling buttons or interactive elements during a click.</p>
                                                </li>
                                                <li>
                                                    <p><strong><code>:focus</code>:</strong> It's commonly used for styling form input fields when they are selected.</p>
                                                </li>
                                                <li>
                                                    <p><strong><code>:nth-child()</code>:</strong> Targets elements based on their position within a parent element. You can use this pseudo-selector to select elements like alternating rows in a table or specific items in a list.</p>
                                                </li>
                                                <li>
                                                    <p><strong><code>:first-child</code> and <code>:last-child</code>:</strong> Target the first and last child elements of a parent. They are often used to style the first or last item in a list or container differently.</p>
                                                </li>
                                                <li>
                                                    <p><strong><code>:not()</code>:</strong> Allows you to select elements that do not match a specific selector. For example, <code>:not(.disabled)</code> selects all elements that do not have the class "disabled."</p>
                                                </li>
                                                <li>
                                                    <p><strong><code>:first-of-type</code> and <code>:last-of-type</code>:</strong> Select the first and last elements of a specific type.</p>
                                                </li>
                                                <li>
                                                    <p><strong><code>:nth-of-type()</code>:</strong> Similar to <code>:nth-child()</code>, but it selects elements based on their position among siblings of the same type.</p>
                                                </li>
                                                <li>
                                                    <p><strong><code>:checked</code>:</strong> Targets form elements like checkboxes and radio buttons that are in a checked state. It's used to style checked options.</p>
                                                </li>
                                                <li>
                                                    <p><strong><code>:before</code> and <code>:after</code>:</strong> These pseudo-selectors are used to insert content before or after the content of an element. </p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>What is the process for crafting a responsive layout using CSS?</h3>
                                            <p>You can use media queries and flexible layout techniques like flexbox or CSS grid to create responsive designs.</p>
                                        </li>
                                        <li>
                                            <h3>Explain the CSS transition property.</h3>
                                            <p><code>transition</code> is used to create smooth transitions between property changes over time.</p>
                                        </li>
                                        <li>
                                            <h3>What role does the @media rule serve in CSS?</h3>
                                            <p><code>@media</code> is used for responsive design, allowing you to apply styles based on different screen sizes and media types.</p>
                                        </li>
                                        <li>
                                            <h3>How can you include multiple CSS classes on an HTML element?</h3>
                                            <p>Separate class names with a space, like <code>class="class1 class2"</code></p>
                                        </li>
                                        <li>
                                            <h3>How do you create a CSS gradient background?</h3>
                                            <p>You can create gradients using <code>linear-gradient()</code> or <code>radial-gradient()</code> as the value for the <code>background</code> property.</p>
                                        </li>
                                        <li>
                                            <h3>What is the purpose of CSS prefixes, and can you name a few prefixes?</h3>
                                            <p>prefixes are used to apply experimental or browser-specific CSS properties. Examples include <code>-webkit-</code>, <code>-moz-</code>, and <code>-ms-</code>.</p>
                                        </li>
                                        <li>
                                            <h3>How can you hide an element in CSS without using <code>display: none;</code>?</h3>
                                            <p>You can use <code>visibility: hidden;</code> to hide an element while still occupying space in the layout.</p>
                                        </li>
                                        <li>
                                            <h3>What is the CSS <code>float </code>property used for?</h3>
                                            <p><code>float</code> is used to align elements to the left or right within their containing element.</p>
                                        </li>
                                        <li>
                                            <h3>What is a CSS pseudo-element?</h3>
                                            <p>A pseudo-element is used to style a specific part of an element, like <code>::before</code> and <code>::after</code> to style content before and after an element.</p>
                                        </li>
                                        <li>
                                            <h3>What is a CSS pseudo-class?</h3>
                                            <p>A pseudo-class is used to define a special state of an element, like <code>:hover</code> for mouse hover or <code>:focus</code> for user input focus.</p>
                                        </li>
                                        <li>
                                            <h3>How can you add comments in CSS?</h3>
                                            <p>CSS comments are added using </p>
                                            <div className='code_highlight_area'>
                                                <div className='code_highlight_header'>
                                                    <h5>CSS</h5>
                                                </div>
                                                <pre>
                                                    <code className="javascript">
                                                        {`
<div>
This is some content
{/* This is a comment */}
</div>
`}
                                                    </code>
                                                </pre>
                                            </div>
                                        </li>
                                        <li>
                                            <h3>How do inline, internal, and external CSS differ from each other?</h3>
                                            <ul>
                                                <li>The inline CSS technique allows us to apply CSS to a single element.</li>
                                                <li>Internal CSS is defined within a <code>&lt;style&gt;</code> tag in the HTML document's <code>&lt;head&gt;</code> section.</li>
                                                <li>External CSS is stored in a separate .css file and linked to the HTML document using the <code>&lt;link&gt;</code> tag.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h3>What is the purpose of CSS?</h3>
                                            <p>CSS is employed to establish the appearance and arrangement of web pages.</p>
                                        </li>
                                        <li>
                                            <h3>What is the CSS clip-path property used for?</h3>
                                            <p><code>clip-path</code> is used to define a visible region of an element, allowing you to create non-rectangular shapes.</p>
                                        </li>
                                        <li>
                                            <h3>How can you include custom fonts in CSS?</h3>
                                            <p>Use <code>@font-face</code> to include custom fonts and then apply them using the <code>font-family</code> property.</p>
                                        </li>

                                    </ol>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* OurCourses */}
                <OurCourses />
            </>
        </>
    )
}

export default CssInterview