export const data=[
    {
        thumb:"/images/no_img_logo.png",
        name:"PNG Wing",
        URL: "https://www.pngwing.com/",
        alt:"webpatches transparent texture images",
        anchorTitle:"webpatches free background images"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Freepik",
        URL: "https://www.freepik.com/",
        alt:"webpatches best for website images",
        anchorTitle:"webpatches "
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"OpenClipart",
        URL: "https://openclipart.org/",
        alt:"webpatches OpenClipart shape and images",
        anchorTitle:"webpatches OpenClipart"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Canva",
        URL: "https://www.canva.com/",
        alt:"webpatches best background images website",
        anchorTitle:"webpatches transparent vector images"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"nnnnnnnnnn",
        URL: "uuuuuuuuuuuuuu",
        alt:"webpatches ",
        anchorTitle:"webpatches "
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Pixabay",
        URL: "https://pixabay.com/",
        alt:"webpatches free background image download",
        anchorTitle:"webpatches free background image download"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Pexels",
        URL: "https://www.pexels.com/",
        alt:"webpatches royal free high quality images download",
        anchorTitle:"webpatches HD images free"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Flaticon",
        URL: "https://www.flaticon.com/",
        alt:"webpatches best website for free icon",
        anchorTitle:"webpatches best website for free icon"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"SVG-Repo",
        URL: "https://www.svgrepo.com/",
        alt:"webpatches free vector icons",
        anchorTitle:"webpatches free vector icons"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Vexels",
        URL: "https://www.vexels.com/",
        alt:"webpatches Vexels provides a vast library of vector graphics",
        anchorTitle:"webpatches Vexels provides a vast library of vector graphics"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Unblast",
        URL: "https://unblast.com/",
        alt:"webpatches Unblast",
        anchorTitle:"webpatches Unblast"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Vectorportal",
        URL: "https://vectorportal.com/",
        alt:"webpatches Free Stock SVG Vector Images, Clip Art and Icons",
        anchorTitle:"webpatches Free Stock SVG Vector Images, Clip Art and Icons"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Vector4free",
        URL: "https://www.vector4free.com/",
        alt:"webpatches Vector4free",
        anchorTitle:"webpatches Vector4free"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"123RF",
        URL: "https://www.123rf.com/",
        alt:"webpatches Images and Vectors Royalty Free Images from 123RF ",
        anchorTitle:"webpatches Images and Vectors Royalty Free Images from 123RF"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Design Bundles",
        URL: "https://designbundles.net/",
        alt:"webpatches Design Bundles",
        anchorTitle:"webpatches Design Bundles"
    },
    

]