export const data= [
    {
        thumb:"/images/no_img_logo.png",
        name:"Cloud Convert",
        URL: "https://cloudconvert.com/font-converter",
        alt:"Webpatches Cloud Font Converter",
        anchorTitle:"Webpatches Cloud Font Converter "
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Font Converter",
        URL: "https://www.fontconverter.org/",
        alt:"Webpatches Font Converter",
        anchorTitle:"Webpatches Font Converter"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Convertio",
        URL: "https://convertio.co/font-converter/",
        alt:"Webpatches Convertio font converter",
        anchorTitle:"Webpatches Convertio font converter"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Trans Fonter",
        URL: "https://transfonter.org/",
        alt:"Webpatches Trans Fonter",
        anchorTitle:"Webpatches Trans Fonter"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Online Font Converter",
        URL: "https://onlinefontconverter.com/",
        alt:"Webpatches Online Font Converter",
        anchorTitle:"Webpatches Online Font Converter"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"FreeConvert",
        URL: "https://www.freeconvert.com/ttf-to-woff",
        alt:"Webpatches FreeConvert",
        anchorTitle:"Webpatches FreeConvert"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Transfonter",
        URL: "https://transfonter.org/",
        alt:"Webpatches Transfonter font converter",
        anchorTitle:"Webpatches Transfonter font converter"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Font2Web",
        URL: "https://www.font2web.com/",
        alt:"Webpatches Font2Web converter",
        anchorTitle:"Webpatches Font2Web converter"
    }
    
]