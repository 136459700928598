import React from 'react'
import { NavLink } from 'react-router-dom'

const data = [
  {
    name: "What is ReactJs",
    url: '/reactjs/what-is-reactjs'
  },
  {
    name: "What are Components in Reactjs?",
    url: '/reactjs/components-in-reactjs'
  },
  {
    name: "What are Props in Reactjs?",
    url: '/reactjs/what-are-props-in-reactjs'
  },
  {
    name: "What is State in Reactjs",
    url: '/reactjs/what-is-state-in-reactjs'
  },
  {
    name: "React Hooks",
    url: '/reactjs/react-hooks'
  }

]

const ReactJsSideNav = () => {
  return (
    <>
      <div className='sideNav_style_comman'>
        <ul>
          {
            data.map((item, val) => (
              <li key={val}>
                <NavLink to={item.url}>{item.name}</NavLink>
              </li>
            ))
          }
        </ul>
      </div>
    </>
  )
}

export default ReactJsSideNav