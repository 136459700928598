import React from 'react'
import { NavLink, Link } from 'react-router-dom'

const data = [
    {
        name: "VS Code Extension enhance the ease of development.",
        url: '/vs-code-extension-for-developers'
    },
    {
        name: "Fundamental Web Design Concepts and Tools",
        url: '/basic-web-design-concept-and-tools'
    }
]


const ArticleSideNav = () => {
    return (
        <>
            <div className='sideNav_style_comman'>
                <ul>
                    {data.map((item, val) => (
                        <li key={val}>
                            <NavLink to={item.url}>{item.name}</NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default ArticleSideNav