import React from 'react'
import BannerInner from '../BannerInner/BannerInner'

function About() {
  return (
    <>
      <BannerInner title="About Us" />
        <h1>About Page</h1>
    </>
  )
}

export default About