import React from 'react'
import CodeHighlightComp from '../Pulgins/CodeHighlight/CodeHighlightComp'
import BannerInner from '../BannerInner/BannerInner'
import ReactJsSideNav from './ReactJsLayout/ReactJsSideNav'
import OurCourses from '../HomePage/OurCourses'

const ReactComponent = () => {
    return (
        <>
            <BannerInner
                title="What are Components in Reactjs?"
                banner_desc="Learn what components are in React and how to create functional and class components. "
            />
            <section className='comman_tb_padding'>
                <div className='container'>
                    <div className='row responsive_row'>
                        <div className='col-lg-3 col-md-4 sideNavbar_comman_main'>
                            <div className='sideNavbar_comman'>
                                <ReactJsSideNav />
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8 content_style_main'>
                            <div className='content_style_comman'>
                                <CodeHighlightComp />
                                <h3>Components in Reactjs</h3>
                                <p>Components are reusable, self-contained units of code that can be composed together to create complex UIs. They are one of the core concepts of React and play a crucial role in organizing and structuring web applications.</p>
                                <p><strong>Types of components in React:</strong></p>
                                
                                <ol>
                                    <li>
                                        <h3>Functional Components:</h3>
                                        <p>These are JavaScript functions that accept props (short for properties) as input and return JSX (JavaScript XML) to describe the UI. Functional components are also sometimes referred to as "stateless" or "dumb" components.</p>
                                        <p>Functional Components are a core concept in React and are widely used for building UI elements.</p>
                                        <div className='code_highlight_area'>
                                            <div className='code_highlight_header'>
                                                <h5>jsx</h5>
                                            </div>
                                            <pre>
                                                <code className="javascript">
                                                    {`
import React from 'react';

function Welcome(props) {
  return <h1>Hello, {props.name}</h1>;
}

export default Welcome;

`}
                                                </code>
                                            </pre>
                                        </div>
                                    </li>
                                    <li>
                                        <h3>Class Components:</h3>
                                        <p>Class components are ES6 classes that extend the React.Component base class. Class Components can manage their own internal state and have access to lifecycle methods. Class components are also known as "stateful" components and are used when you need to manage and update component state.</p>
                                        <div className='code_highlight_area'>
                                            <div className='code_highlight_header'>
                                                <h5>jsx</h5>
                                            </div>
                                            <pre>
                                                <code className="javascript">
                                                    {`
import React, { Component } from 'react';

class Counter extends Component {
  constructor(props) {
    super(props);
    this.state = { count: 0 };
  }

  render() {
    return (
      <div>
        <p>Count: {this.state.count}</p>
        <button onClick={() => this.setState({ count: this.state.count + 1 })}>Increment</button>
      </div>
    );
  }
}

export default Counter;
`}
                                                </code>
                                            </pre>
                                        </div>
                                    </li>
                                </ol>
                                <p>In both types of components, you define the UI structure using JSX, which allows you to write HTML-like code within your JavaScript. Components can accept input data through props, which are passed from parent to child components. Props are immutable, implying that the component receiving them cannot alter their values.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  */}
            <OurCourses />
        </>
    )
}

export default ReactComponent