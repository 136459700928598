import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom';


const ScrollPage = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0,0);
    }, [pathname]);
  return (
    <>
    
    </>
  )
}

export default ScrollPage