import React from 'react'
import BannerInner from '../BannerInner/BannerInner'
import ReactJsSideNav from './ReactJsLayout/ReactJsSideNav'
import OurCourses from '../HomePage/OurCourses'

const ReactHooks = () => {
    return (
        <>
            <BannerInner
                title="A Comprehensive Guide to React Hooks, State and Effects in Functional Components"
                banner_desc="React Hooks simplify the management of state and side effects in functional components, promoting cleaner and more maintainable code. "
            />
            <section className='comman_tb_padding'>
                <div className='container'>
                    <div className='row responsive_row'>
                        <div className='col-lg-3 col-md-4 sideNavbar_comman_main'>
                            <div className='sideNavbar_comman'>
                                <ReactJsSideNav />
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8 content_style_main'>
                            <div className='content_style_comman'>
                                <h3>What is React Hooks</h3>
                                <p>React Hooks are functions designed to enable functional components to access and utilize React's state and lifecycle capabilities. They were introduced in React version 16.8 as a way to add state and side-effect capabilities to functional components, which were previously only available in class components. React Hooks provide a more flexible and concise way to manage component logic, making it easier to reuse code and separate concerns within your React applications.</p>
                                <h3>Type of React Hook Here:</h3>

                                <ol>
                                    <li>
                                        <p><strong>useState:</strong> Permits the incorporation of local state within a functional component by providing both a state variable and a corresponding function for modifying its value This is useful for managing component-specific data that can change over time.</p>
                                    </li>
                                    <li>
                                        <p><strong>useEffect:</strong> Enables you to perform side effects in functional components.  lifecycle methods like componentDidMount, componentDidUpdate, and componentWillUnmount. You can use it for tasks like data fetching, DOM manipulation, and event subscriptions.</p>
                                    </li>
                                    <li>
                                        <p><strong>useContext:</strong> Provides access to the React context, allowing functional components to consume values from context providers. It's useful for passing data and functions between components without prop drilling.</p>
                                    </li>
                                    <li>
                                        <p><strong>useReducer:</strong> An alternative to <code>useState</code>, especially suitable for managing complex state logic. It's often used when you need to track multiple related state variables or when state transitions are more complex.</p>
                                    </li>
                                    <li>
                                        <p><strong>useRef:</strong> Creates mutable references to DOM elements or other values that persist across renders. This is often used when you need to interact directly with the DOM or maintain a reference to a value without causing re-renders.</p>
                                    </li>
                                    <li>
                                        <p><strong>useMemo and useCallback:</strong> These hooks are used for performance optimization by memoizing expensive calculations or function references, preventing unnecessary re-computation or re-rendering.</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  */}
            <OurCourses />
        </>
    )
}

export default ReactHooks