import React from 'react'
import { Helmet } from "react-helmet";
import { NavLink, Link } from 'react-router-dom'
import OurCourses from './OurCourses';

function Home() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
      <style>{"body { backgound-color: red !important; } .stiky_header {display: none !important;}"}</style>
        {/* <meta name="description" content="We help students study MBBS Abroad 2023 and DoctorBano is most trusted Best MBBS Abroad consultant in Delhi. Get direct admission in MBBS abroad without NEET." />
        <meta name="Keywords" content="Study MBBS Abroad 2023, MBBS Abroad Consultants in Delhi, Best MBBS Abroad consultant in Delhi, Direct admission in MBBS abroad, Direct admission in MBBS abroad without NEET" />
        <link rel="canonical" href="https://webpatches.com/" />
        <meta name="robots" content="index, follow" />
        <meta name="og:type" property="og:type" content="website" />
        <meta name="og:url" property="og:url" content="https://webpatches.com/" />
        <meta name="og:title" property="og:title" content="Enhance Web Design and Development with User-Friendly Tools | Boost Website Search Efficiency" />
        <meta name="og:description" property="og:description" content="We help students study MBBS Abroad 2023 and DoctorBano is most trusted Best MBBS Abroad consultant in Delhi. Get direct admission in MBBS abroad without NEET." />
        <meta name="og:image" property="og:image" content="https://webpatches.com/images/webpatches2.png" />
        <meta name="twitter:card" content="summary" /> */}
        
      </Helmet>
      <section className='banner_sec'>
        <div class="line-animation">
          <div class="line1">
            <img src="https://www.txtgpt.ai/themes/images/line-1.png" alt="" />
          </div>
          <div class="line2">
            <img src="https://www.txtgpt.ai/themes/images/line-2.png" alt="" />
          </div>
          <div class="line1">
            <img src="https://www.txtgpt.ai/themes/images/line-1.png" alt="" />
          </div>
          <div class="line2">
            <img src="https://www.txtgpt.ai/themes/images/line-2.png" alt="" />
          </div>
          <div class="line1">
            <img src="https://www.txtgpt.ai/themes/images/line-1.png" alt="" />
          </div>
          <div class="line2">
            <img src="https://www.txtgpt.ai/themes/images/line-2.png" alt="" />
          </div>
        </div>
        <div id="demo" className="carousel slide" data-bs-ride="carousel">
          {/* Indicators/dots */}
          {/* <div className="carousel-indicators">
          <button type="button" data-bs-target="#demo" data-bs-slide-to={0} className="active" />
          <button type="button" data-bs-target="#demo" data-bs-slide-to={1} />
          <button type="button" data-bs-target="#demo" data-bs-slide-to={2} />
        </div> */}
          {/* The slideshow/carousel */}
          <div className="carousel-inner">
            <div className="carousel-item active">


              <div className="container">
                <div className="row banner_row">
                  <div className="col-lg-6 col-md-6 col-sm-12 banner_txt d-flex align-items-center">
                    <div className="banner_txt_box">
                      <h1>It offers all the necessary solutions for a particular need in a single place</h1>

                      <NavLink className="banner_btn- shiny_btn_style" to="/categories-list">View Resources</NavLink>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 banner_img">
                    <img src="images/webpatches2.png" alt="" title="" />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="carousel-item">
            <img src="chicago.jpg" alt="Chicago" className="d-block" style={{width: '100%'}} />
          </div>
          <div className="carousel-item">
            <img src="ny.jpg" alt="New York" className="d-block" style={{width: '100%'}} />
          </div> */}
          </div>
          {/* Left and right controls/icons */}
          {/* <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" />
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
          <span className="carousel-control-next-icon" />
        </button> */}
        </div>
      </section>
      {/*  */}
      <section className="about_home">
        <div className="container">
          <div className="row clearfix">
            <div className="col-sm-12">
              <h4>We are passionate designers and developers</h4>
              <p>We are a dedicated team of designers and developers who are deeply passionate about our craft. Our mission is to simplify and optimize the design process by offering a comprehensive solution all in one place, eliminating the need to navigate multiple sources.</p>

              <p>Our platform provides a one-stop destination for all your design needs, ensuring convenience and efficiency for our valued developers. Bid farewell to the inconvenience of navigating multiple websites.</p>

              <p>One of the primary benefits of using Webpatches is its time-saving and efficiency-enhancing feature. Designers can now avoid the need to visit multiple websites to fulfill their requirements. It also provides convenience and streamlines the process of finding solutions to complex problems.</p>
              {/*<img src="images/developemt_bg.png" alt="" />*/}
            </div>
          </div>
        </div>
      </section>
      {/* courses */}
      <OurCourses />
      {/*  */}
      <section className='latest_article_sec bg_color_light_gray'>
        <div className='container'>
          <div className='site_features_area '>
            <div className='heading_box'>
              <h2>Latest Article</h2>
            </div>
            <div className='comman_top'>
              <div className='row'>
                <div className='col-lg-6 design_grid_card'>
                  <div className="card_over_vidget">
                    <div className="grid">
                      <figure className="effect-lily ">
                        <div className='card_img'>
                          <img src="images/interview_ques_webpatches.jpg" alt="" />
                        </div>
                        <figcaption>
                          <div>
                            <h4>HTML/CSS</h4>
                            <h2>Comprehensive HTML and CSS Interview Questions with Expert Answers</h2>
                          </div>
                          <Link to="/interview/top-css-interview-questions">View more</Link>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='row'>
                    <div className='col-lg-6 design_grid_card'>
                      <div className="card_over_vidget">
                        <div className="grid">
                          <figure className="effect-lily ">
                            <div className='card_img'>
                              <img src="images/test_img1.jpg" alt="very usefull extensions for VS Code Editoraa" />
                            </div>
                            <figcaption>
                              <div>
                                {/* <h4>Design Resources</h4> */}
                                <h2>Visual Studio Code extensions enhance and streamline the development process</h2>
                              </div>
                              <Link to="/vs-code-extension-for-developers">View more</Link>
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 design_grid_card'>
                      <div className="card_over_vidget">
                        <div className="grid">
                          <figure className="effect-lily ">
                            <div className='card_img'>
                              <img src="images/design_concept_webpatches.jpg" alt="" />
                            </div>
                            <figcaption>
                              <div>
                                <h2>Exploring Fundamental Web Design Concepts and Tools.</h2>
                              </div>
                              <Link to="/basic-web-design-concept-and-tools">View more</Link>
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 design_grid_card'>
                      <div className="card_over_vidget">
                        <div className="grid">
                          <figure className="effect-lily ">
                            <div className='card_img'>
                              <img src="images/browser_extension_img.jpg" alt="web patches browser extension for developers" />
                            </div>
                            <figcaption>
                              <div>
                                <h2>Essential Browser Extensions for Website Development</h2>
                              </div>
                              <Link to="/browser-tools/what-font-browser-extension">View more</Link>
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 design_grid_card'>
                      <div className="card_over_vidget">
                        <div className="grid">
                          <figure className="effect-lily ">
                            <div className='card_img'>
                              <img src="images/general_knowledge_webpatches.jpg" alt="" />
                            </div>
                            <figcaption>
                              <div>
                                <h2>General Knowledge</h2>
                              </div>
                              <Link to="gk/first-in-india-gk-questions-with-answers">View more</Link>
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      

      {/* hero_resources_sec */}
      <section className='hero_resources_sec'>
        <div className='container'>
          <div className='text-center'>
            {/* <h3>Find Better List of Web Development and Design Tools</h3> */}
            <h3>What are the top tools for website development?</h3>
            <NavLink to="/categories-list" className="shiny_btn_style">Resources</NavLink>
          </div>

        </div>
      </section>
    </>
  )
}

export default Home