import React from 'react'
import BannerInner from './BannerInner/BannerInner'
import { Link } from 'react-router-dom'
import OurCourses from './HomePage/OurCourses'

const PrivacyPolicy = () => {
  return (
    <>
        <BannerInner 
            title="Privacy Policy" 
            banner_desc="We may disclose your information in accordance with legal requirements, to enforce our Terms of Use, or to safeguard our rights, privacy, safety, or property."
        />
        <section className='comman_tb_padding'>
            <div className='container'>
                <div className='content_style_comman'>
                    <p>Welcome to WebPatches. We are dedicated to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your data when you access or use <Link to="https://webpatches.com/">https://webpatches.com/</Link></p>
                    <p>When using our Website, you acknowledge your awareness, understanding, and consent to the practices detailed in this Privacy Policy. If you do not align with this Privacy Policy, kindly abstain from using our Website.</p>

                    <h2>How We Use Your Information</h2>
                    <p><strong>Providing and Enhancing Services:</strong> To provide, maintain, and enhance the Website and its content, ensuring a better experience for you.</p>
                    <p><strong>Personalization:</strong> To personalize your experience by offering resources, content, and recommendations tailored to your interests and preferences.</p>
                    <p><strong>User Support:</strong> To respond to your inquiries and provide user support.</p>
                    <p><strong>Improvement:</strong> To gather analytics and statistics for improving our services, content, and user experience.</p>

                    <h3>Third-Party Links</h3>
                    <p>Our Website may provide connections to external third-party websites or valuable resources. Kindly note that we do not assume responsibility for the privacy practices of these third parties. We recommend reviewing the privacy policies of these third-party websites before providing them with personal information.</p>

                    <h3>Security</h3>
                    <p>We employ prudent measures to safeguard your personal information against unauthorized access, disclosure, alteration, or harm. Nevertheless, it's crucial to recognize that complete security of data transmission over the internet is elusive, and we cannot provide an unassailable guarantee of absolute security.</p>

                    <h3>Changes to this Privacy Policy</h3>
                    <p>We reserve the right to periodically revise this Privacy Policy to align with evolving practices, operational requirements, legal obligations, or regulatory considerations. Any such revisions will become effective upon publication of the updated Privacy Policy on this page. We recommend reviewing this Privacy Policy regularly.</p>

                    {/* <h3>Contact Us</h3>
                    <p>If you have questions, concerns, or requests related to this Privacy Policy or your personal information, please contact us at [Your Contact Information].</p> */}
                </div>
            </div>
        </section>
        {/*  */}
        <OurCourses />
    </>
  )
}

export default PrivacyPolicy