import React from 'react'
import { data } from './SideNavBarData'
import { NavLink } from 'react-router-dom'

function SideNavBar() {



  return (
    <>
      <ul>
        {data.map((item, val) => (
          <li key={val}>
            <NavLink to={item.url}>{item.name}</NavLink>
          </li>
        ))}
      </ul>
    </>
  )
}

export default SideNavBar