import React from 'react'
import BannerInner from '../BannerInner/BannerInner'
import CssCourseSideNav from './CssCourseLayout/CssCourseSideNav'
import CodeHighlightComp from '../Pulgins/CodeHighlight/CodeHighlightComp'
import OurCourses from '../HomePage/OurCourses'

const CenterElementBothSide = () => {
    return (
        <>
            <>
                <BannerInner
                    title="How can I center an element horizontally and vertically in CSS?"
                    banner_desc="explore various methods to achieve this alignment, including flexbox and CSS grid techniques, as well as older approaches like using absolute positioning and negative margins. "
                />
                <section className='comman_tb_padding'>
                    <div className='container'>
                        <div className='row responsive_row'>
                            <div className='col-lg-3 col-md-4 sideNavbar_comman_main'>
                                <div className='sideNavbar_comman'>
                                    <CssCourseSideNav />
                                </div>
                            </div>
                            <div className='col-lg-9 col-md-8 content_style_main'>
                                <div className='content_style_comman'>
                                    <CodeHighlightComp />
                                    <h2>Centering Elements Both Horizontally and Vertically with CSS</h2>
                                    <p>You can center an element both horizontally and vertically in CSS using a variety of techniques. Here are three commonly used methods:</p>
                                    <ol>
                                        <li>
                                            <p><strong>Using Flexbox (Recommended for Centering):</strong></p>
                                            <div className='code_highlight_area'>
                                                <div className='code_highlight_header'>
                                                    <h5>CSS</h5>
                                                </div>
                                                <pre>
                                                    <code className="javascript">
                                                        {`
.center-element {
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
}
`}
                                                    </code>
                                                </pre>
                                            </div>
                                            <p>In this approach, you create a container element (e.g., <code>&lt;div class="center-element"&gt;</code>) and apply <code>display: flex;</code> to it. This makes it a flex container. <code>justify-content: center;</code> centers the content horizontally, while <code>align-items: center;</code> centers it vertically. This method is versatile and highly recommended for centering elements.</p>

                                        </li>
                                        <li>
                                            <p><strong>Using Absolute Positioning (for Unknown Element Size):</strong></p>
                                            <div className='code_highlight_area'>
                                                <div className='code_highlight_header'>
                                                    <h5>CSS</h5>
                                                </div>
                                                <pre>
                                                    <code className="javascript">
                                                        {`
.center-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
`}
                                                    </code>
                                                </pre>
                                            </div>
                                            <p>With this method, you set the element's position to <code>absolute</code>, and then you use <code>top: 50%;</code> and <code>left: 50%;</code> to move the top-left corner of the element to the center of its containing element. Finally, <code>transform: translate(-50%, -50%);</code> shifts the element back by half its width and height, effectively centering it.</p>

                                        </li>
                                        <li>
                                            <p><strong>Using Grid (for Centering Multiple Elements):</strong></p>
                                            <div className='code_highlight_area'>
                                                <div className='code_highlight_header'>
                                                    <h5>CSS</h5>
                                                </div>
                                                <pre>
                                                    <code className="javascript">
                                                        {`
.container {
  display: grid;
  place-items: center;
  height: 100vh; /* Adjust this for desired centering within the viewport */
}
`}
                                                    </code>
                                                </pre>
                                            </div>
                                            <p>If you want to center multiple elements within a container, CSS Grid can be an excellent choice. Apply <code>display: grid;</code> to the container and <code>place-items: center;</code> to center both horizontally and vertically. Adjust the <code>height</code> property to determine the centering within the viewport.</p>

                                        </li>

                                    </ol>
                                    <p>Flexbox is the most versatile and widely recommended approach for centering elements because of its ease of use and flexibility.</p>



                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*  */}
                <OurCourses />
            </>
        </>
    )
}

export default CenterElementBothSide