import React from 'react'
import { NavLink } from 'react-router-dom'

const HtmlSideNav = () => {
    return (
        <>
            <div className='sideNav_style_comman'>
                <ul>
                    <li><NavLink to="/html/html-content-editable-attribute" >HTML contenteditable Attribute</NavLink></li>
                    <li><NavLink to="/html/figure-caption-element">Figure Caption Element</NavLink></li>
                </ul>
            </div>
        </>
    )
}

export default HtmlSideNav