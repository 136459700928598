import React from 'react'
import { Link } from 'react-router-dom'
const RelatadeArticle = () => {
    return (
        <>
            <section className='comman_tb_padding bg_color_light_gray'>
                <div className='container'>
                    <div className='heading_box'>
                        <h2>Future Reading</h2>
                    </div>
                    <div className='row comman_top'>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 blog_box_main d-flex wow fadeInUp">
                            <div className="blog_box_h_inner">
                                <div className="blog_img_box upcomimg_events_box_inner">
                                    <div className="upcomimg_events_img_box"> <img src="images/vs_code_extension.jpg" alt="blog img" /> </div>
                                </div>
                                <div className="blog_txt_area">
                                    <h4><a className="blog_h_head">VS Code Extension enhance the ease of development.</a></h4>
                                    <p>VS Code extension enhance the ease of development across various programming languages and workflows.</p>
                                    <a className="blog_btn">Read More</a> 
                                </div>
                                <Link className="blog_btn_over" to="/vs-code-extension-for-developers"></Link> 
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 blog_box_main d-flex wow fadeInUp">
                            <div className="blog_box_h_inner">
                                <div className="blog_img_box upcomimg_events_box_inner">
                                    <div className="upcomimg_events_img_box"> <img src="images/web_designing-concept-and-tools.jpg" alt="blog img" /> </div>
                                </div>
                                <div className="blog_txt_area">
                                    <h4><a className="blog_h_head">Exploring Fundamental Web Design Concepts and Tools.</a></h4>
                                    <p>It encompasses gaining a deeper understanding of design fundamentals like layout, </p>
                                    <p className="blog_btn">Read More</p> 
                                </div>
                                <Link className="blog_btn_over" to="/basic-web-design-concept-and-tools"></Link> 
                            </div>
                        </div>
                        
                        {/* <div className='col-lg-4 normal_card_box'>
                            <div className='normal_card_box_inner'>

                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
export default RelatadeArticle

// const RelatedArticle = () => {
//   return (
//     <div>RelatedArticle</div>
//   )
// }

// export default RelatedArticle


