export const data= [
    {
        thumb:"/images/no_img_logo.png",
        name:"Figma",
        URL: "https://www.figma.com/",
        alt:"Webpatches Figma Design tool",
        anchorTitle:"Webpatches Figma Design tool"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Photoshop",
        URL: "https://www.adobe.com/in/products/photoshop.html",
        alt:"Webpatches Photoshop best editing tools",
        anchorTitle:"Webpatches Photoshop best editing tools"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Zeplin",
        URL: "https://zeplin.io/",
        alt:"Webpatches Zeplin",
        anchorTitle:"Webpatches Zeplin"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Corel Draw",
        URL: "https://www.coreldraw.com/en/",
        alt:"Webpatches Corel Draw desiging tool for printing media",
        anchorTitle:"Webpatches Corel Draw desiging tool for printing media"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Adobe Illustrator",
        URL: "uuuuuu",
        alt:"Webpatches Adobe Illustrator Graphic Design",
        anchorTitle:"Webpatches Adobe Illustrator Graphic Design"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"ttt",
        URL: "uuuuuu",
        alt:"Webpatches ",
        anchorTitle:"Webpatches "
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Adobe XD",
        URL: "https://helpx.adobe.com/in/xd/get-started.html",
        alt:"Webpatches Adobe XD",
        anchorTitle:"Webpatches Adobe XD"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Sketch",
        URL: "https://www.sketch.com/",
        alt:"Webpatches Sketch is a professional vector graphics design tool",
        anchorTitle:"Webpatches Sketch is a professional vector graphics design tool"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Affinity Designer",
        URL: "https://affinity.serif.com/en-gb/designer/",
        alt:"Webpatches Affinity Designer",
        anchorTitle:"Webpatches Affinity Designer"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Affinity",
        URL: "https://affinity.serif.com/en-us/",
        alt:"Webpatches Affinity",
        anchorTitle:"Webpatches Affinity"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Canva",
        URL: "https://www.canva.com/en_in/",
        alt:"Webpatches Canva photo editing tool",
        anchorTitle:"Webpatches Canva photo editing tool"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Adobe InDesign",
        URL: "https://www.adobe.com/products/indesign.html",
        alt:"Webpatches Adobe InDesign",
        anchorTitle:"Webpatches Adobe InDesign"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Pixlr",
        URL: "https://pixlr.com/",
        alt:"Webpatches Pixlr",
        anchorTitle:"Webpatches Pixlr"
    }
    
]