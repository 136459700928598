export const data= [
    {
        thumb:"/images/no_img_logo.png",
        name:"Google Fonts",
        URL: "https://fonts.google.com/",
        alt:"Webpatches Free font download",
        anchorTitle:"Webpatches Free font download"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"100 Free Fonts",
        URL: "https://www.1001freefonts.com/",
        alt:"Webpatches 100 Free Fonts",
        anchorTitle:"Webpatches 100 Free Fonts"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Dafont",
        URL: "https://www.dafont.com/",
        alt:"Webpatches Dafont free font download",
        anchorTitle:"Webpatches Dafont free font download"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Lost Type",
        URL: "https://losttype.com/",
        alt:"Webpatches Lost Type",
        anchorTitle:"Webpatches Lost Type"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Creative Market",
        URL: "https://creativemarket.com/fonts",
        alt:"Webpatches Creative Market",
        anchorTitle:"Webpatches Creative Market"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Fontsquirrel",
        URL: "https://www.fontsquirrel.com/",
        alt:"Webpatches fontsquirrel",
        anchorTitle:"Webpatches fontsquirrel"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"BE Font",
        URL: "https://befonts.com/",
        alt:"Webpatches BE Font is the best font",
        anchorTitle:"Webpatches BE Font is the best font"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"FF Font",
        URL: "https://www.ffonts.net/",
        alt:"Webpatches FF Font best font collection",
        anchorTitle:"Webpatches FF Font best font collection"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"FontSpace",
        URL: "https://www.fontspace.com/",
        alt:"Webpatches FontSpace provides free and premium fonts",
        anchorTitle:"Webpatches FontSpace provides free and premium fonts"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"1001 Fonts",
        URL: "https://www.1001fonts.com/",
        alt:"Webpatches 1001 Fonts is the large collection of free fonts",
        anchorTitle:"Webpatches 1001 is the large collection of free fonts"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Fontfabric",
        URL: "https://www.fontfabric.com/",
        alt:"Webpatches its quality and uniqueness.",
        anchorTitle:"Webpatches its quality and uniqueness."
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"FontZone",
        URL: "https://fontzone.net/",
        alt:"Webpatches FontZone",
        anchorTitle:"Webpatches FontZone"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"FontZone",
        URL: "https://fontzone.net/",
        alt:"Webpatches FontZone is large selection of free fonts",
        anchorTitle:"Webpatches FontZone is large selection of free fonts"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Free Script Fonts",
        URL: "https://www.freescriptfonts.net/",
        alt:"Free Script Fonts",
        anchorTitle:"Free Script Fonts "
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Fonts Arena",
        URL: "https://fontsarena.com/",
        alt:"Webpatches Fonts Arena",
        anchorTitle:"Webpatches Fonts Arena"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"nnnnnnnnn",
        URL: "uuuuuuuuu",
        alt:"Webpatches ",
        anchorTitle:"Webpatches "
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Pinspiry",
        URL: "https://pinspiry.com/category/free-resources/fonts/",
        alt:"Webpatches Pinspiry for website and desktop",
        anchorTitle:"Webpatches Pinspiry for website and desktop"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Unblast",
        URL: "https://unblast.com/fonts/",
        alt:"Webpatches unblast font for website",
        anchorTitle:"Webpatches unblast font for website"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Gluk Fonts",
        URL: "https://www.glukfonts.pl/fonts.php?l=en",
        alt:"easy to download Gluk Fonts",
        anchorTitle:"easy to download Gluk Fonts"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Font Space",
        URL: "https://www.fontspace.com/",
        alt:"Font Space provides handpicked fonts",
        anchorTitle:"Webpatches Font Space provides handpicked fonts"
    }
    
    
]