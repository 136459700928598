import React from 'react'
import { NavLink } from 'react-router-dom'

const BrowserSideNav = () => {
    return (
        <>
            <div className='sideNav_style_comman'>
                <ul>
                    <li><NavLink to="/browser-tools/what-font-browser-extension" >Browser Extensions for Identifying Fonts Name.</NavLink></li>
                    <li><NavLink to="/browser-tools/full-page-screen-capture-browser-extension">Full page Screen Capture</NavLink></li>
                    <li><NavLink to="/browser-tools/wappalyzer-website-technology-identifier">Exploring the Wappalyzer Browser Extension</NavLink></li>
                </ul>
            </div>

        </>
    )
}

export default BrowserSideNav