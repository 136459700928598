export const data=[
    {
        thumb:"/images/fonts_finder_myfonts.png",
        name:"MyFonts",
        URL: "https://www.myfonts.com/pages/whatthefont",
        alt:"webpatches MyFonts finder",
        anchorTitle:"webpatches MyFonts finder"
    },
    {
        thumb:"/images/fonts_finder_adobe.png",
        name:"Adobe Fonts",
        URL: "https://fonts.adobe.com/fonts",
        alt:"webpatches Adobe Fonts finder",
        anchorTitle:"webpatches Adobe Fonts finder"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Identifont",
        URL: "https://www.identifont.com/",
        alt:"webpatches Identifont finder",
        anchorTitle:"webpatches Identifont finder"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Font Squirrel",
        URL: "https://www.fontsquirrel.com/matcherator",
        alt:"webpatches Font Squirrel",
        anchorTitle:"webpatches Font Squirrel"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Font ID",
        URL: "https://fontid.co/",
        alt:"webpatches Font ID ",
        anchorTitle:"webpatches Font ID"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Font Meme",
        URL: "https://fontmeme.com/font-identifier/",
        alt:"webpatches Font Meme",
        anchorTitle:"webpatches Font Meme"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Befonts",
        URL: "https://befonts.com/font-finder",
        alt:"webpatches Befonts",
        anchorTitle:"webpatches Befonts"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"WhatFontIs",
        URL: "https://www.whatfontis.com/",
        alt:"webpatches WhatFontIs",
        anchorTitle:"webpatches WhatFontIs"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Fontjoy",
        URL: "https://fontjoy.com/",
        alt:"webpatches Fontjoy Font Matcher",
        anchorTitle:"webpatches Fontjoy Font Matcher"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Font Bundles",
        URL: "https://fontbundles.net/font-finder",
        alt:"webpatches Font Bundles Font Identifier",
        anchorTitle:"webpatches Font Bundles Font Identifier"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Creative Market",
        URL: "https://creativemarket.com/fonts",
        alt:"webpatches Creative Market Font Identifier",
        anchorTitle:"webpatches Creative Market Font Identifier"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Linotype Font",
        URL: "https://www.linotype.com/font-identifier.html",
        alt:"webpatches Linotype Font Identifier",
        anchorTitle:"webpatches Linotype Font Identifier"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Fontspring",
        URL: "https://www.fontspring.com/font-identifier",
        alt:"webpatches Fontspring Font Identifier",
        anchorTitle:"webpatches Fontspring Font Identifier"
    }

]