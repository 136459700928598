import React from 'react'
import {Link, NavLink} from 'react-router-dom'


function Footer() {
  return (
    <>
        {/* footer_sec */}
      <section className="footer_sec"> 
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer_box">
                <h2 className="ft_heading">Design Resources</h2>
                <div className="ft_links">
                  <ul>
                  <li><NavLink to="/resources/icons" >Icons Finder</NavLink></li>
                  <li><NavLink to="/resources/fonts-finder">Image to Fonts Finder</NavLink></li>
                  <li><NavLink to="/resources/font-converter">Font Converter</NavLink></li>
                  <li><NavLink to="/resources/designing-tools">Designing Tools</NavLink></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer_box">
                <h2 className="ft_heading">Browser Extension</h2>
                <div className="ft_links">
                  <ul>
                    <li><NavLink to="/browser-tools/what-font-browser-extension" >Browser Extensions for Identifying Fonts Name.</NavLink></li>
                    <li><NavLink to="/browser-tools/full-page-screen-capture-browser-extension">Full page Screen Capture</NavLink></li>
                    <li><NavLink to="/browser-tools/wappalyzer-website-technology-identifier">Exploring the Wappalyzer Browser Extension</NavLink></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer_box">
                <h2 className="ft_heading">Interview Questions</h2>
                <div className="ft_links">
                  <ul>
                    <li><Link to="/interview/top-css-interview-questions">CSS Interview Questions</Link></li>
                    <li><Link to="/interview/top-html-interview-questions">HTML Interview Questions</Link></li>
                    <li><Link to="/interview/top-reactjs-interview-questions">React Interview Questions</Link></li>
                    <li><Link to="/interview/bootstrap-interview-questions">Bootstrap Interview Questions</Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer_box">
                <h2 className="ft_heading">CSS</h2>
                <div className="ft_links">
                  <ul>
                    <li><NavLink to="/css/what-is-css" >About CSS</NavLink></li>
                    <li><NavLink to="/css/include-css-in-html" >Include CSS</NavLink></li> 
                    <li><NavLink to="/css/center-element-both-side" >Horizontally and Vertically Center</NavLink></li>
                    <li><NavLink to="/css/css-selectors" >CSS Selectors</NavLink></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* copyright */}
      <section className='footer_copyright_sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 footer_copy'>
              <p>©2023 Webpatches, All Rights Reserved.</p>
            </div>
            <div className='col-lg-6 footer_privacy'>
              <ul>
                <li>
                  <Link to="/privacy-policy" >Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-conditions" >Terms & Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer