import React from 'react'
import ResourcesSubBanner from '../Resources/ResourcesLayout/ResourcesSubBanner'

const FirstIndia = () => {
  return (
    <>
      <ResourcesSubBanner
        title="A Collection of Top Icon Finder Websites"
        banner_desc="Curated a list of top websites offering premium icon sets for purchase, alongside options for free icon downloads. Explore a wide list of icon categories and packs."
      />

      <section>
        <div className='content_style_comman'>
          <ol>
            <li>
              <h4>Who was the first President of India?</h4>
              <p><strong>Answer:</strong> Dr. Rajendra Prasad</p>
            </li>
            <li>
              <h4>Who was the first Prime Minister of India?</h4>
              <p><strong>Answer:</strong> Jawaharlal Nehru</p>
            </li>
            <li>
              <h4>Who was the first woman Prime Minister of India?</h4>
              <p><strong>Answer:</strong> Indira Gandhi</p>
            </li>
            <li>
              <h4>What was the first Indian state to be formed on a linguistic basis?</h4>
              <p><strong>Answer:</strong> Andhra Pradesh</p>
            </li>
            <li>
              <h4>Who was the first woman Chief Minister of an Indian state?</h4>
              <p><strong>Answer:</strong> Sucheta Kriplani (Uttar Pradesh)</p>
            </li>
            <li>
              <h4>What was India's first satellite called?</h4>
              <p><strong>Answer:</strong> Aryabhata</p>
            </li>
            <li>
              <h4>Who was the first Indian to win an individual Olympic gold medal?</h4>
              <p><strong>Answer:</strong> Abhinav Bindra (2008, Beijing)</p>
            </li>
            <li>
              <h4>Who was the first Indian to climb Mount Everest?</h4>
              <p><strong>Answer:</strong> Tenzing Norgay (along with Sir Edmund Hillary, a New Zealander)</p>
            </li>
            <li>
              <h4>Which was the first university in India?</h4>
              <p><strong>Answer:</strong> Nalanda University</p>
            </li>
            <li>
              <h4>Who was the first Indian in space?</h4>
              <p><strong>Answer:</strong> Rakesh Sharma</p>
            </li>
            <li>
              <h4>Who was the first Indian woman in space?</h4>
              <p><strong>Answer:</strong> Kalpana Chawla</p>
            </li>
            <li>
              <h4>What was the first feature film made in India?</h4>
              <p><strong>Answer:</strong> Raja Harishchandra (1913, directed by Dadasaheb Phalke)</p>
            </li>
            <li>
              <h4>Who was the first Indian to receive the Nobel Prize?</h4>
              <p><strong>Answer:</strong> Rabindranath Tagore (Literature, 1913)</p>
            </li>
            <li>
              <h4>Who was the first Indian to receive the Nobel Prize in Physics?</h4>
              <p><strong>Answer:</strong> C. V. Raman (1930)</p>
            </li>
            <li>
              <h4>Who was the first Chief Justice of India?</h4>
              <p><strong>Answer:</strong> Harilal Jekisundas Kania</p>
            </li>
            <li>
              <h4>What was the first newspaper in India?</h4>
              <p><strong>Answer:</strong> The Bengal Gazette (also known as The Calcutta General Advertiser, published by James Augustus Hicky in 1780)</p>
            </li>
            <li>
              <h4>Who was the first Indian to receive the Bharat Ratna posthumously?</h4>
              <p><strong>Answer:</strong> Lal Bahadur Shastri (1966)</p>
            </li>
            <li>
              <h4>Who was the first Indian to win the Nobel Prize in Chemistry?</h4>
              <p><strong>Answer:</strong> Venkatraman Ramakrishnan (2009)</p>
            </li>
            <li>
              <h4>What was the first city in India to have a local train service?</h4>
              <p><strong>Answer:</strong> Mumbai</p>
            </li>
            <li>
              <h4>Who was the first Indian woman to become a commercial pilot?</h4>
              <p><strong>Answer:</strong> Durba Banerjee (1936)</p>
            </li>
            <li>
              <h4>What was the first Indian Institute of Technology (IIT) established?</h4>
              <p><strong>Answer:</strong> IIT Kharagpur (1951)</p>
            </li>
            <li>
              <h4>Who was the first Indian to receive the Rajiv Gandhi Khel Ratna Award, India's highest sporting honor?</h4>
              <p><strong>Answer:</strong> Viswanathan Anand (1991-1992)</p>
            </li>
            <li>
              <h4>Who was the first Indian to win an individual Olympic gold medal in athletics?</h4>
              <p><strong>Answer:</strong>  Abhinav Bindra</p>
            </li>
            <li>
              <h4>What was the first Indian film to be nominated for an Academy Award (Oscar)?</h4>
              <p><strong>Answer:</strong> "Mother India" (1957, nominated for Best Foreign Language Film)</p>
            </li>
            <li>
              <h4>Who was the first Indian woman to become the Chief Minister of a state?</h4>
              <p><strong>Answer:</strong>  Sucheta Kriplani (Uttar Pradesh, 1963)</p>
            </li>
            <li>
              <h4>What was the first Indian airline to operate a commercial flight?</h4>
              <p><strong>Answer:</strong> Tata Airlines (now known as Air India, 1932)</p>
            </li>
            <li>
              <h4>Who was the first Indian to win the Miss World title?</h4>
              <p><strong>Answer:</strong> Reita Faria (1966)</p>
            </li>
            <li>
              <h4>What was the first Indian city to have an international airport?</h4>
              <p><strong>Answer:</strong> Mumbai (Chhatrapati Shivaji Maharaj International Airport)</p>
            </li>
            <li>
              <h4>What was the first official language of India after independence?</h4>
              <p><strong>Answer:</strong> Hindi</p>
            </li>
            
          </ol>
        </div>
      </section>

    </>
  )
}

export default FirstIndia