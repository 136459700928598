import React from "react";
import BannerInner from '../BannerInner/BannerInner'
import CssCourseSideNav from './CssCourseLayout/CssCourseSideNav'
import CodeHighlightComp from '../Pulgins/CodeHighlight/CodeHighlightComp'
import OurCourses from "../HomePage/OurCourses";

const CssSelector = () =>{
    return(
        <>

            <BannerInner
                title="css Selectors"
                banner_desc=""
            />
            <section className='comman_tb_padding'>
                <div className='container'>
                    <div className='row responsive_row'>
                        <div className='col-lg-3 col-md-4 sideNavbar_comman_main'>
                            <div className='sideNavbar_comman'>
                                <CssCourseSideNav />
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8 content_style_main'>
                            <div className='content_style_comman'>
                                <CodeHighlightComp />
                                <h3>What are CSS selectors, and what are some common types of selectors?</h3>
                                <p>CSS selectors are patterns used in Cascading Style Sheets (CSS) to select and style specific HTML elements on a web page.</p>
                                <ol>
                                    <li>
                                        <h3><strong>Element Selector </strong></h3>
                                        <p><p>Selects all instances of a specific HTML element. For example, the following CSS rule selects all <code>&lt;p&gt;</code> elements and sets their text color to red:</p></p>
                                        <div className='code_highlight_area'>
                                            <div className='code_highlight_header'>
                                                <h5>CSS</h5>
                                            </div>
                                            <pre>
                                                <code className="javascript">                    {`
p {
    color: red;
  }
`}
                                                </code>
                                            </pre>
                                        </div>
                                    </li>
                                    <li>
                                        <h3>Class Selector:</h3>
                                        <p>Selects elements with a specific <code>class</code> attribute value. Class selectors are prefixed with a dot (<code>.</code>). For example, this selector selects all elements with the class "my-class":</p>
                                        <div className='code_highlight_area'>
                                            <div className='code_highlight_header'>
                                                <h5>CSS</h5>
                                            </div>
                                            <pre>
                                                <code className="javascript">
                                                    {`
.my-class {
    font-weight: bold;
  }  
`}
                                                </code>
                                            </pre>
                                        </div>
                                        <div className='code_highlight_area'>
                                            <div className='code_highlight_header'>
                                                <h5>HTML</h5>
                                            </div>
                                            <pre>
                                                <code className="javascript">
                                                    {`
<p class="my-class"> </p>
`}
                                                </code>
                                            </pre>
                                        </div>
                                    </li>
                                    <li>
                                        <h3>ID Selector:</h3>
                                        <p>Selects a single element with a specific <code>id</code> attribute value. ID selectors are prefixed with a hash (<code>#</code>). Example here.</p>
                                        <div className='code_highlight_area'>
                                            <div className='code_highlight_header'>
                                                <h5>CSS</h5>
                                            </div>
                                            <pre>
                                                <code className="javascript">
                                                    {`
#unique-element {
    background-color: yellow;
  }  
`}
                                                </code>
                                            </pre>
                                        </div>
                                        <div className='code_highlight_area'>
                                            <div className='code_highlight_header'>
                                                <h5>HTML</h5>
                                            </div>
                                            <pre>
                                                <code className="javascript">
                                                    {`
<div id="unique-element"><p>This is ID Selector</p></div>
`}
                                                </code>
                                            </pre>
                                        </div>
                                        
                                    </li>
                                    <li>
                                        <h3>Attribute Selector</h3>
                                        <p>Selects elements based on their attributes and attribute values. For example, you can select all links with a specific <code>target</code> attribute value like this:</p>
                                        <div className='code_highlight_area'>
                                            <div className='code_highlight_header'>
                                                <h5>CSS</h5>
                                            </div>
                                            <pre>
                                                <code className="javascript">
                                                    {`
a[target="_blank"] {
    text-decoration: underline;
}  
`}
                                                </code>
                                            </pre>
                                        </div>
                                    </li>
                                    <li>
                                        <h3>Descendant Selector:</h3>
                                        <p>Selects elements that are descendants of a specified parent element. For example, to select all <code>&lt;li&gt;</code> elements that are descendants of a <code>&lt;ul&gt;</code> element, you can use the descendant selector:</p>
                                        <div className='code_highlight_area'>
                                            <div className='code_highlight_header'>
                                                <h5>CSS</h5>
                                            </div>
                                            <pre>
                                                <code className="javascript">
                                                    {`
ul li {
    list-style-type: square;
} 
`}
                                                </code>
                                            </pre>
                                        </div>
                                    </li>
                                    <li>
                                        <h3>Child Selector:</h3>
                                        <p>Selects elements that are direct children of a specified parent element. This is denoted by the <code>&gt;</code> symbol. For example, to select only the direct child <code>&lt;li&gt;</code> elements of a <code>&lt;ul&gt;</code> element</p>
                                        <div className='code_highlight_area'>
                                            <div className='code_highlight_header'>
                                                <h5>CSS</h5>
                                            </div>
                                            <pre>
                                                <code className="javascript">
                                                    {`
ul > li {
    font-weight: bold;
  }  
`}
                                                </code>
                                            </pre>
                                        </div>
                                    </li>
                                    <li>
                                        <h3>Pseudo-classes:</h3>
                                        <p>Pseudo-classes select elements based on their state or position. Common pseudo-classes include <code>:hover</code> (for mouse hover), <code>:focus</code> (for input focus), and <code>:nth-child()</code> (for selecting elements by their position in a parent).</p>
                                        <div className='code_highlight_area'>
                                            <div className='code_highlight_header'>
                                                <h5>CSS</h5>
                                            </div>
                                            <pre>
                                                <code className="javascript">
                                                    {`
a:hover {
    color: green;
}
    
`}
                                                </code>
                                            </pre>
                                        </div>
                                    </li>


                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  */}
            <OurCourses />

        </>
    )
}
export default CssSelector