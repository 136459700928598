import React from 'react'
import { NavLink } from 'react-router-dom'

const data = [
    {
        name: "CSS Interview Questions",
        url: '/interview/top-css-interview-questions'
    },
    {
        name: "HTML Interview Questions",
        url: '/interview/top-html-interview-questions'
    },
    {
        name: "React Interview Questions",
        url: '/interview/top-reactjs-interview-questions'
    },
    {
        name: "Bootstrap Interview Questions",
        url: '/interview/bootstrap-interview-questions'
    },
]


const InterviewQuesSideNav = () => {
    return (
        <>
            <div className='sideNav_style_comman'>
                <ul>
                    {data.map((item, val) => (
                        <li key={val}>
                            <NavLink to={item.url}>{item.name}</NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default InterviewQuesSideNav