import React from 'react'
import CodeHighlightComp from '../Pulgins/CodeHighlight/CodeHighlightComp'
import BannerInner from '../BannerInner/BannerInner'
import ReactJsSideNav from './ReactJsLayout/ReactJsSideNav'
import OurCourses from '../HomePage/OurCourses'

const PropsReact = () => {
    return (
        <>
            <BannerInner
                title="What are Props in Reactjs?"
                banner_desc="Explore how to pass data from parent to child components using props."
            />
            <section className='comman_tb_padding'>
                <div className='container'>
                    <div className='row responsive_row'>
                        <div className='col-lg-3 col-md-4 sideNavbar_comman_main'>
                            <div className='sideNavbar_comman'>
                                <ReactJsSideNav />
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8 content_style_main'>
                            <div className='content_style_comman'>
                                <CodeHighlightComp />
                                <h3>Components in Reactjs</h3>
                                <p>In React.js, "props" is short for "properties," and it refers to a mechanism for passing data from a parent component to a child component. Props allow you to transmit data or configuration information down the component tree, enabling the child components to render with the appropriate data or behavior. Props are a fundamental concept in React and are essential for building reusable and dynamic user interfaces.</p>

                                <div className='code_highlight_area'>
                                    <div className='code_highlight_header'>
                                        <h5>jsx</h5>
                                    </div>
                                    <pre>
                                        <code className="javascript">
                                            {`
import React from 'react';

function ParentComponent() {
  // Data to be passed as props
  const name = "John Doe";

  return (
    <div>
      <h1>Welcome to My App</h1>
      {/* Passing "name" as a prop to ChildComponent */}
      <ChildComponent name={name} />
    </div>
  );
}

function ChildComponent(props) {
  return <p>Hello, {props.name}!</p>;
}

export default ParentComponent;

`}
                                        </code>
                                    </pre>
                                </div>
                                <p>Props are a powerful way to make your React components dynamic and reusable. They allow you to customize the behavior and appearance of child components based on the data provided by their parent components.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  */}
            <OurCourses />
        </>
    )
}

export default PropsReact