import React from 'react'
import { NavLink } from 'react-router-dom'

const data = [
    {
        name: "About CSS",
        url: '/css/what-is-css'
    },
    {
        name: "Include CSS",
        url: "/css/include-css-in-html"
    },
    {
        name: "Horizontally and Vertically Center",
        url: "/css/center-element-both-side"
    },
    {
        name: "CSS Selectors",
        url: "/css/css-selectors"
    }
]


const CssCourseSideNav = () => {
    return (
        <>
            <div className='sideNav_style_comman'>
                <ul>
                    {data.map((item, val) => (
                        <li key={val}>
                            <NavLink to={item.url}>{item.name}</NavLink>
                        </li>
                    ))}
                    {/* <li><NavLink to="/css/what-is-css" >About CSS</NavLink></li>
                    <li><NavLink to="/css/include-css-in-html" >Include CSS</NavLink></li> 
                    <li><NavLink to="/css/center-element-both-side" >Horizontally and Vertically Center</NavLink></li> */}

                </ul>
            </div>
        </>
    )
}

export default CssCourseSideNav