export const data= [
    {
        thumb:"/images/no_img_logo.png",
        name:"Flat Icon",
        URL: "https://www.flaticon.com/",
        alt:"Webpatches best icon finder",
        anchorTitle:"Webpatches best icon finder"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Freepik",
        URL: "https://www.freepik.com/",
        alt:"Webpatches freepik icon finder",
        anchorTitle:"Webpatches freepik icon finder"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Feather",
        URL: "https://feathericons.com/",
        alt:"Webpatches Feather icon finder",
        anchorTitle:"Webpatches Feather icon finder"
    },
    {
        thumb:"/images/icon-creative-market-webpatches.png",
        name:"Creative Market",
        URL: "https://creativemarket.com/icons",
        alt:"Webpatches Creative Market",
        anchorTitle:"Webpatches Creative Market"
    },
    {
        thumb:"/images/icon-icon-finder-webpatches.png",
        name:"Icon Finder",
        URL: "https://www.iconfinder.com/?ref=arunace",
        alt:"webpatches best icon finder",
        anchorTitle:"webpatches best icon finder"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Icons8",
        URL: "https://icons8.com/",
        alt:"webpatches Icon8 icon finder",
        anchorTitle:"webpatches Icon8 icon finder"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Vecteezy",
        URL: "https://www.vecteezy.com/free-vector/icons",
        alt:"webpatches Vecteezy icon finder",
        anchorTitle:"icon anchor title 1webpatches Vecteezy icon finder"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Endless Icons",
        URL: "https://endlessicons.com/",
        alt:"webpatches Endless Icons",
        anchorTitle:"webpatches Endless Icons"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Thesquid ink",
        URL: "https://thesquid.ink/flat-icons/",
        alt:"webpatches thesquid ink",
        anchorTitle:"webpatches thesquid ink"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Icon Store",
        URL: "https://iconstore.co/",
        alt:"webpatches Icon Store",
        anchorTitle:"webpatches Icon Store"
    },
    {
        thumb:"/images/no_img_logo.png",
        name:"Iconmonstr",
        URL: "https://iconmonstr.com/",
        alt:"webpatches Iconmonstr",
        anchorTitle:"webpatches Iconmonstr"
    }
    
]