import React from 'react'

function ResourcesItems(props) {
    const {data} = props
    console.log("DATA: ",data)
    //console.log("TEST: ",test)
  return (
    <>
        <div className="col-6 col-sm-4 col-md-4 pl-md-0 reso_listing_box">
            <div className="reso_listing_card">
                <div className="reso_logo">
                    <img src={data.thumb} alt={data.alt} />
                </div>
                <div className="reso_body">
                    <h3>{data.name}</h3>
                </div>
                <a className='readmore_overlay' href={data.URL} target="_blank" title={data.anchorTitle}> </a>
            </div>
        </div>
    </>
  )
}

export default ResourcesItems