import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom'

function Header() {

  const [theme, setTheme] = useState('theme-light');

  // Function to set the theme and save it to localStorage
  function setAndSaveTheme(themeName) {
    localStorage.setItem('dibiz_theme', themeName);
    document.documentElement.className = themeName;
    setTheme(themeName);
  }

  // Function to toggle between light and dark theme
  function toggleTheme() {
    if (theme === 'theme-dark') {
      setAndSaveTheme('theme-light');
    } else {
      setAndSaveTheme('theme-dark');
    }
  }

  // Set the theme on initial load
  useEffect(() => {
    const storedTheme = localStorage.getItem('dibiz_theme');
    if (storedTheme) {
      setAndSaveTheme(storedTheme);
    }
  }, []);

// 




  return (
    <>
      <nav className="navbar navbar-expand-lg sticky-top nav_sec">
        <div className="container">
          <div className="logo_box"> <NavLink className to="/"><img src="/images/logo.png" alt="" /></NavLink> </div>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Programs
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><Link className="dropdown-item" to="/reactjs/what-is-reactjs">React Js</Link></li>
                  <li><Link className="dropdown-item" to="/html/html-content-editable-attribute">HTML Programs</Link></li>
                  <li><Link className="dropdown-item" to="/css/center-element-both-side">CSS</Link></li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink to="/browser-tools/what-font-browser-extension">Browser Extension</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/categories-list">Design Tools</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/interview/top-css-interview-questions">Interview Preparation</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/vs-code-extension-for-developers">Blog</NavLink>
              </li>
              {/* <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Dropdown
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item" href="#">Action</a></li>
                        <li><a className="dropdown-item" href="#">Another action</a></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                    </li>  */}

            </ul>
            {/* <form className="d-flex">
                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success" type="submit">Search</button>
                    </form> */}
          </div>
        </div>
      </nav>

      {/* <nav className="nav_sec" id="sticky-wrap">
        <div className="container cus_container">
          <div className="nav_inner">
            <div className="logo_area">
              <div className="logo_box"> <NavLink className to="/"><img src="/images/logo.png" alt="" /></NavLink> </div>
            </div>
            <div className="nav_area">
              <div className="stellarnav">
                <ul>
                  <li><NavLink to="javascript:void(0);">Programs</NavLink>
                    <ul>
                      <li><NavLink to="/reactjs/what-is-reactjs">React Js</NavLink></li>
                      <li><NavLink to="/html/html-content-editable-attribute">HTML Programs</NavLink></li>
                      <li><NavLink to="/css/center-element-both-side">CSS</NavLink></li>
                      
                    </ul>
                  </li>
                  <li><NavLink to="/browser-tools/what-font-browser-extension">Browser Extension</NavLink></li>
                  <li><NavLink to="/categories-list">Design Tools</NavLink></li>
                </ul>
              </div>
            </div>
            <div className="site_vision_area">
              <div className="switch-box">
                <label id="switch" className={`switch ${theme}`}>
                <input type="checkbox" onChange={toggleTheme} checked={theme === 'theme-dark'} id="slider" />
                  <span className="slider round" />
                </label>
              </div>
            </div> 
          </div>
        </div>
      </nav> */}
    </>
  )
}

export default Header