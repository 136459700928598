import React, { useEffect } from 'react'
import hljs from 'highlight.js';
import 'highlight.js/styles/felipec.css';

const CodeHighlightComp = () => {
    useEffect(() => {
        hljs.initHighlightingOnLoad();
    
      }, []);

  return (
    <>
        
    </>
  )
}

export default CodeHighlightComp
