import './App.css';
import React from 'react';


import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollPage from './Components/ScrollTo/ScrollPage';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Home from './Components/HomePage/Home';
import About from './Components/AboutUsPage/About';
// import IconPage from './Components/Resources/Pages/IconPage';
import CategoriesList from './Components/Resources/ResourcesCategory/CategoriesList';

// import LayoutResources from './Components/Resources/LayoutResources'
// Resources Import List
import LayoutResources from './Components/Resources/ResourcesLayout/LayoutResources';
import FontFinderPage from './Components/Resources/PagesResources/FontFinderPage/FontFinderPage';
import IconPage from './Components/Resources/PagesResources/IconPage/IconPage';
import FontConverter from './Components/Resources/PagesResources/FontConverter/FontConverter';
import DesigningTool from './Components/Resources/PagesResources/DesigningTool/DesigningTool';
import Fonts from './Components/Resources/PagesResources/FontPage/Fonts';
import GraphicsVector from './Components/Resources/PagesResources/GraphicsVector/GraphicsVector';


// Article Import

import BasicWebConcept from './Components/Article/BasicWebConcept';
import VsCodeExtension from './Components/Article/VsCodeExtension';

// Browser Extension 
import WhatFontExtension from './Components/BrowserTools/WhatFontExtension';
import FullPageScreenCaptureExt from './Components/BrowserTools/FullPageScreenCaptureExt';
import WappalyzerExt from './Components/BrowserTools/WappalyzerExt';

// Reactjs course Router
import WhatsReactJs from './Components/ReactJs/WhatsReactJs';
import ReactComponent from './Components/ReactJs/ReactComponent';
import PropsReact from './Components/ReactJs/PropsReact';
import StateReact from './Components/ReactJs/StateReact';
import ReactHooks from './Components/ReactJs/ReactHooks';


// HtmlCourse Router
import ContentEditableAttribute from './Components/HtmlCourse/ContentEditableAttribute';
import Figcaption from './Components/HtmlCourse/Figcaption';


// CSS Course Import
import CenterElementBothSide from './Components/CssCourse/CenterElementBothSide';
import WhatsCss from './Components/CssCourse/WhatsCss';
import IncludeCssInHtml from './Components/CssCourse/IncludeCssInHtml';
import CssSelector from './Components/CssCourse/CssSelector';

// Interviews Import
import CssInterview from './Components/InterviewQuestion/CssInterview';
import HtmlInterview from './Components/InterviewQuestion/HtmlInterview';
import ReactInterview from './Components/InterviewQuestion/ReactInterview';
import BootstrapInterview from './Components/InterviewQuestion/BootstrapInterview';

// General Knowledge Questions
import GkLayout from './Components/GK/GkLayout/GkLayout';


// 
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsConditions from './Components/TermsConditions';
//404 page
import NotFound from './Components/NotFoundPage/NotFound';
import FirstIndia from './Components/GK/FirstIndia';
















function App() {

  

  return (
    <>
      {/* <Header />
      

      <Footer /> */}

      <BrowserRouter>
        <Header />
        <ScrollPage />
        
        <div className='main'>
          <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='about-us' element={<About />} />

            <Route path='categories-list' element={<CategoriesList />} />
            {/* Article Router */}
            
            <Route path='basic-web-design-concept-and-tools' element={<BasicWebConcept />} />
            <Route path='vs-code-extension-for-developers' element={<VsCodeExtension />} />

            {/* ReactJs Router */}
            <Route path='reactjs/what-is-reactjs' element={<WhatsReactJs />}></Route>
            <Route path='reactjs/components-in-reactjs' element={<ReactComponent />}></Route>
            <Route path='reactjs/what-are-props-in-reactjs' element={<PropsReact />}></Route>
            <Route path='reactjs/what-is-state-in-reactjs' element={<StateReact />}></Route>
            <Route path='reactjs/react-hooks' element={<ReactHooks />}></Route>


            {/* HTML Course Router */}
            <Route path='html/html-content-editable-attribute'  element={<ContentEditableAttribute />}/>
            <Route path='html/figure-caption-element'  element={<Figcaption />}/>

            {/* CSS Course Router */}
            <Route path='css/center-element-both-side' element={<CenterElementBothSide />}/> 
            <Route path='css/what-is-css' element={<WhatsCss />}/> 
            <Route path='css/include-css-in-html' element={<IncludeCssInHtml />}/> 
            <Route path='css/css-selectors' element={<CssSelector />}/> 

            {/* Interview Questions Router */}
            <Route path='interview/top-css-interview-questions' element={<CssInterview />}/>
            <Route path='interview/top-html-interview-questions' element={<HtmlInterview />}/>
            <Route path='interview/top-reactjs-interview-questions' element={<ReactInterview />}/>
            <Route path='interview/bootstrap-interview-questions' element={<BootstrapInterview />}/>    

            {/* Browser Tool Router */}
            {/* <Route path='what-font-browser-extension' element={<BrowserToolLayout Children={<WhatFontExtension />} />}></Route> */}
            <Route path='browser-tools/what-font-browser-extension' element={<WhatFontExtension />}></Route>
            <Route path='browser-tools/full-page-screen-capture-browser-extension' element={<FullPageScreenCaptureExt />}></Route>
            <Route path='browser-tools/wappalyzer-website-technology-identifier' element={<WappalyzerExt />}></Route>
            
            {/* Resources Router */}
            <Route path='resources/icons' element={<LayoutResources Children={<IconPage/>}/>}/>
            <Route path='resources/fonts-finder'  element={<LayoutResources Children={<FontFinderPage/>}/>}/>
            <Route path='resources/font-converter'  element={<LayoutResources Children={<FontConverter/>}/>}/>
            <Route path='resources/designing-tools'  element={<LayoutResources Children={<DesigningTool />}/>}/>
            <Route path='resources/fonts'  element={<LayoutResources Children={<Fonts />}/>}/>
            <Route path='resources/graphics-and-vector'  element={<LayoutResources Children={<GraphicsVector />}/>}/>


            {/* General Knowledge Question */}
            <Route path='gk/first-in-india-gk-questions-with-answers' element={<GkLayout Children={<FirstIndia />}/>}/>


            {/* PrivacyPolicy */}
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='terms-conditions' element={<TermsConditions />} />
            {/* 404 page */}            
            <Route path="*" element={<NotFound />} />

          </Routes>
        </div>
        <Footer />
        
      </BrowserRouter>

      
    </>
  );
}

export default App;
