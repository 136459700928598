import React from 'react'

import BannerInner from '../../BannerInner/BannerInner'
import OurCourses from '../../HomePage/OurCourses'
import ResourcesSubBanner from '../../Resources/ResourcesLayout/ResourcesSubBanner'
// import HeroComponent from './HeroComponent'
// import {APIDATA} from './APIDATA'

 const GkLayout = ({Children}) => {
//     const [apiData,setAPIData] = useState(null)
//     console.log("INFORMation: ",apiData)
//     const [selectedCategory,setSelectedCategory] = useState("icons")

//     console.log("SELECTED: ",selectedCategory)

//     useEffect(()=>{
//         const fetchData = async ()=>{
//             const data = APIDATA
//             setAPIData(data)
//         }
//         fetchData()
//     })
  return (
    <>
        {/* <BannerInner title="page name.." />  */}
        
        <section className='resources_page_sec lll'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3'>
                        <div className="sidenav_bar">
                            {/* <SideNavBar  /> */}
                            side nav space
                        </div>
                    </div>
                    <div className='col-lg-9'>
                        <div className='resources_page_right'>
                            {Children}
                        </div>                        
                    </div>
                </div>
            </div>
        </section>
        <OurCourses />
    </>
  )
}

export default GkLayout