import React from 'react'
import {data} from '../ResourcesLayout/SideNavBarData'
import { NavLink } from 'react-router-dom'
import BannerInner from '../../BannerInner/BannerInner'
// const data = [
//   {
//     name: "Icon Finder",
//     url: '/resources/icons'
//   },
//   {
//     name: "Image to Fonts Finder",
//     url: '/resources/fonts-finder'
//   },
//   {
//     name: "Font Converter",
//     url: '/resources/font-converter'
//   },
//   {
//     name: "Designing Tools",
//     url: '/resources/designing-tools'
//   },

// ]

function CategoriesList() {
  return (
    <>
      <BannerInner
        title="Resourceful Tools and References for Designers and Web Developers"
        banner_desc="Explore essential assets, insightful guides, and time-saving 
        utilities to enhance your skills and efficiency for Designers and Developers."
      />
      <section className='comman_tb_padding'>
        <div className='container'>
          <div className='row'>
            {data.map((item, val) => (
              <div className='col-lg-3 col-md-4 cate_box_main'>
              <div className='cate_box'>
                <h4>{item.name}</h4>
                {/* <NavLink className="link_btn_comman" to="{item.url}">{item.name}</NavLink> */}
                <NavLink className="link_btn_comman" to={item.url}>Explore</NavLink>
              </div>
            </div>
            ))}
            
            
          </div>
        </div>
      </section>
    </>
  )
}

export default CategoriesList