import React, { useState } from 'react'
import { data } from './FontsData'
import ResourcesItems from '../../ResourcesLayout/ResourcesItems'
import ResourcesSubBanner from '../../ResourcesLayout/ResourcesSubBanner';

function Fonts() {
    const [searchInput, setSearchInput] = useState('');
    const filteredData = data.filter(item =>
        item.name.toLowerCase().includes(searchInput.toLowerCase())
    );


    return (
        <>
            <ResourcesSubBanner
                title="Fonts"
                banner_desc=""
            />
            <div className='resources_area'>
                <div className='row resources_title_area'>
                    <div className='col-lg-8 resources_title_lft mb-4'>
                        <h2>Fonts</h2>
                    </div>
                    <div className='col-lg-4 resources_title_search mb-4'>
                        <div className=''>
                            <input className="form-control" type="text" name="" placeholder="Search Menu..."
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='resources_listing'>
                    <div className='row resources_listing_row'>
                        {
                            filteredData.length === 0 ? (
                                <div className="alert alert-danger p-1 px-2 m-0" role="alert">
                                    No matching results found.
                                </div>
                            ) : (
                                filteredData.map((item, index) => (
                                    <ResourcesItems data={item} key={index} />
                                ))
                            )
                        }
                    </div>
                </div>

            </div>
        </>
    )
}

export default Fonts