import React from 'react'
import BannerInner from '../BannerInner/BannerInner'
import BrowserSideNav from './BrowserToolLayout/BrowserSideNav'

const WhatFontExtension = () => {
  return (
    <>
      <BannerInner
        title="WhatFont: An Amazing Browser Extension for Identifying Font Names."
        banner_desc="This extension is a useful tool for web designers, developers, 
          and anyone interested in typography, as it makes it easy to identify and learn 
          about the fonts used on websites. "
      />
      <section className='comman_tb_padding'>
        <div className='container'>
          <div className='row responsive_row'>
            <div className='col-lg-3 col-md-4 sideNavbar_comman_main'>
              <div className='sideNavbar_comman'>
                <BrowserSideNav />
              </div>
            </div>
            <div className='col-lg-9 col-md-8 content_style_main'>
              <div className='content_style_comman'>
                <h3>Browser Extensions for Identifying Fonts</h3>
                <p><strong>"WhatFont"</strong> is a browser extension that assists web designers, developers, and typography enthusiasts in identifying and inspecting fonts on websites. It simplifies the process of discovering the typography used in the design of web pages, making it a valuable tool for those interested in web design, typography, or simply curious about the fonts they come across while browsing the internet.</p>

                <p><strong>Extension Name</strong>: WhatFont</p>

                <h3>Features</h3>
                <ul>
                  <li><strong>Font Identification:</strong> WhatFont allows users to easily hover over text elements on a webpage and obtain information about the fonts being used. It provides details such as the font family, size, line height, and color.</li>
                  <li><strong>Color Picker</strong> In addition to fonts, the extension offers a color picker tool that helps users identify the colors used in various elements on a webpage. This is helpful for replicating color schemes or finding the exact color codes.</li>
                  <li><strong>Efficiency:</strong> WhatFont extension streamlines the process of font identification, saving designers and developers time when working on web projects.</li>
                  <li><strong>Customization:</strong> Users can customize the extension's appearance and behavior to suit their preferences. This includes adjusting the extension's activation mode (hover or click), as well as the information displayed when inspecting text.</li>
                  <li><strong>Persistent Mode:</strong> It includes a persistent mode that allows users to keep the extension active while navigating through a website, making it easier to identify fonts across multiple pages.</li>
                  <li><strong>Consistency:</strong> Designers can use the extension to maintain design consistency by replicating fonts and colors from websites they admire.</li>
                  <li><strong>Exporting:</strong> The extension provides the option to export font and color information, enabling users to save and analyze the typography and color choices for reference.</li>

                </ul>

                <h3>How to Use</h3>
                <ol>
                  <li>
                    <strong>Installation</strong>: You can install the WhatFont extension in supported web browsers such as Google Chrome and Mozilla Firefox from their respective extension stores.
                  </li>
                  <li>
                    <p><strong>Activation</strong>: Once installed, you can activate the extension by clicking its icon in your browser's toolbar.</p>
                  </li>
                  <li>
                    <strong>Hover over Text</strong>: With the extension activated, you can hover your mouse cursor over any text on a webpage. The extension will then display information about the font being used, including the font name, size, line height, and color.
                  </li>
                  <li>
                    <strong>Click for More Details</strong>: If you want more information about the font, you can click on the text element. This will often provide additional details such as the font stack and a preview of the text in the identified font.
                  </li>
                </ol>

                <h3>Preview Demo Images</h3>
                <div className='img_output_preview'>
                  <img className='w-100' src='/images/what_font_browser_extension_webpatches.jpg' alt='web patches what font browser extension' />
                </div>

                <h3>Conclusion</h3>
                <p>In summary, the "WhatFont" browser extension is a handy tool for font and color identification on the web. It enhances the browsing experience for web professionals and enthusiasts by simplifying the process of discovering and learning from the typography and color choices used in web design.</p>


              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default WhatFontExtension