import React from 'react'
import CodeHighlightComp from '../Pulgins/CodeHighlight/CodeHighlightComp'
import BannerInner from '../BannerInner/BannerInner'
import HtmlSideNav from './HtmlCourseLayout/HtmlSideNav'
import OurCourses from '../HomePage/OurCourses'

const Figcaption = () => {
    return (
        <>
            <BannerInner
                title="What is the purpose of the 'figure' and 'figcaption' elements in HTML5? "
                banner_desc="In simple terms, 'figure' and 'figcaption' help you create organized and easy-to-understand content with images and their explanations."
            />
            <section className='comman_tb_padding'>
                <div className='container'>
                    <div className='row responsive_row'>
                        <div className='col-lg-3 col-md-4 sideNavbar_comman_main'>
                            <div className='sideNavbar_comman'>
                                <HtmlSideNav />
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8 content_style_main'>
                            <div className='content_style_comman'>
                                <CodeHighlightComp />
                                <h3>HTML &lt;figure&gt; and &lt;figcaption&gt; Tag</h3>
                                <p>The "figure" and "figcaption" elements in HTML5 are used to enhance the structure and semantics of web documents when you want to associate a caption or description with an image, illustration, diagram, chart, or any other content that can be considered a figure.</p>
                                <p><strong>'figure':</strong> The "figure" element is used to encapsulate the content you want to associate with a caption. It represents any content that is referenced from the main content of the document and can stand on its own. This might include images, illustrations, diagrams, videos, code samples, and more.</p>
                                <p><strong>'figcaption':</strong> The "figcaption" element is used to provide a caption or description for the content enclosed within the "figure" element. It's typically placed inside the "figure" element to provide context or an explanation for the content.</p>

                                <h3><strong>Example</strong></h3>
                                <div className='code_highlight_area'>
                                    <div className='code_highlight_header'>
                                        <h5>html</h5>
                                    </div>
                                    <pre>
                                        <code className="javascript">
                                            {`
    <figure>
        <img src="solar-system.jpg" alt="Solar System">
        <figcaption>An illustration of our solar system's planets and their orbits.</figcaption>
    </figure>
`}
                                        </code>
                                    </pre>
                                </div>
                                <h3><strong>Why are use</strong></h3>
                                <ol>
                                    <li>
                                        <p>Accessibility: It improves the accessibility of web content by providing a text description or context for visual content. Screen readers and other assistive technologies can use the <code>&lt;figcaption&gt;</code> element to provide a more informative description to users who may have visual impairments.</p>
                                    </li>
                                    <li>
                                        <p>SEO: Search engines use captions to better understand the content of images and other media. By using <code>&lt;figcaption&gt;</code>, you can provide descriptive text that can help improve the search engine optimization (SEO) of your webpage.</p>
                                    </li>
                                    <li>
                                        <p>Structured Content: It helps structure your content, making it clear that the text is related to a particular image or media element. This aids in content organization and readability.</p>
                                    </li>
                                    <li>
                                        <p>Improved User Experience: Including captions can enhance the overall user experience by providing context or explanations for images, making the content more informative and engaging.</p>
                                    </li>
                                    <li>
                                        <p>Consistency: Using <code>&lt;figcaption&gt;</code> promotes consistency in the presentation of captions throughout your website. This is particularly useful when you have multiple images with captions, as it ensures a uniform style and layout.</p>
                                    </li>
                                    <li>
                                        <p>Styling Flexibility: You can style <code>&lt;figcaption&gt;</code> elements using CSS to match your website's design and layout preferences. This allows you to control the visual presentation of captions.</p>
                                    </li>
                                </ol>
                                <h3>Conclusion</h3>
                                <p>The "figure" and "figcaption" elements in HTML5 serve a specific purpose in structuring web documents by providing a clear and semantic way to associate content, such as images, diagrams, illustrations, and other figures, with their corresponding captions or descriptions. This enhances the accessibility, search engine optimization, and overall organization of web content. By using "figure" and "figcaption" elements, web developers can create well-structured and easy-to-understand documents, improving the user experience for all visitors, including those with disabilities, while also providing search engines with valuable context about the content. These elements are a valuable addition to the HTML toolbox for creating meaningful and accessible web pages.</p>







                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  */}
            <OurCourses />
        </>
    )
}

export default Figcaption