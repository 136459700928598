import React, { useState } from 'react'
import ResourcesItems from '../../ResourcesLayout/ResourcesItems'
import { data } from './iconPageData'
import ResourcesSubBanner from '../../ResourcesLayout/ResourcesSubBanner'

// const data=[
//     {
//         thumb:"images/icon-creative-market-webpatches.png",
//         name:"Creative Market",
//         URL: "https://creativemarket.com/icons",
//         alt:"Webpatches Creative Market",
//         anchorTitle:"Webpatches Creative Market"
//     },
//     {
//         thumb:"images/icon-icon-finder-webpatches.png",
//         name:"Icon Finder",
//         URL: "https://www.google.com/",
//         alt:"img alt tag 2",
//         anchorTitle:"icon anchor title 2"
//     },
//     {
//         thumb:"images/img2.png",
//         name:"Icon name2",
//         URL: "https://www.google.com/",
//         alt:"img alt tag 3",
//         anchorTitle:"icon anchor title 3"
//     },
//     {
//         thumb:"https://img.freepik.com/free-photo/cocktail-glass-with-ice-cubes-garnished-with-dried-lemon-fruit_141793-1983.jpg?w=900&t=st=1665060788~exp=1665061388~hmac=3a7515e51f811ea9e3ca8ef3a5bf6300bf3c02c073dae17cee834cad00632076",
//         name:"Icon name1",
//         URL: "https://www.google.com/",
//         alt:"img alt tag 1",
//         anchorTitle:"icon anchor title 1"
//     },
//     {
//         thumb:"images/img1.png",
//         name:"Icon name2",
//         URL: "https://www.google.com/",
//         alt:"img alt tag 2",
//         anchorTitle:"icon anchor title 2"
//     },
//     {
//         thumb:"images/img2.png",
//         name:"Icon name2",
//         URL: "https://www.google.com/",
//         alt:"img alt tag 3",
//         anchorTitle:"icon anchor title 3"
//     }
// ]

function IconPage(props) {
    const [searchInput, setSearchInput] = useState('');
    const filteredData = data.filter(item =>
        item.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    

    return (
        <>

            <ResourcesSubBanner 
            title="A Collection of Top Icon Finder Websites"
            banner_desc="Curated a list of top websites offering premium icon sets for purchase, alongside options for free icon downloads. Explore a wide list of icon categories and packs." 
            />
            <div className='resources_area'>
                <div className='row resources_title_area'>
                    <div className='col-lg-8 resources_title_lft mb-4'>
                        <h2>Icon Finder</h2>
                    </div>
                    <div className='col-lg-4 resources_title_search mb-4'>
                        <div className=''>
                            <input className="form-control" type="text" name="" placeholder="Search Menu..."
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className='resources_listing'>
                    <div className='row resources_listing_row'>
                        {
                            filteredData.length === 0 ? (
                                <div className="alert alert-danger p-1 px-2 m-0" role="alert">
                                    No matching results found.
                                </div>
                            ) : (
                                filteredData.map((item, index) => (
                                    <ResourcesItems data={item} key={index} />
                                ))
                            )
                        }
                        {/* {
                        
                        data.map(function(ele){
                            return(
                                <ResourcesItems data={ele}    key={ele} />
                            )
                        }, [])
                    } */}



                    </div>
                </div>

            </div>
        </>
    )
}

export default IconPage