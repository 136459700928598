import React from 'react'

function BannerInner(props) {



  return (
    <>
        <section className='banner_inner_sec'>
            <div className='container'>
              <h1>{props.title}</h1>
              <p>{props.banner_desc}</p>
              {/* <button className="shiny_btn_style">Shiny</button> */}
              
            </div>
            {/*  */}
            
        </section>
    </>
  )
}

export default BannerInner