import React from 'react'

function ResourcesSubBanner(props) {



  return (
    <>
        <div className='resources_sub_banner'>
              <h1>{props.title}</h1>
              <p>{props.banner_desc}</p>
              {/* <button className="shiny_btn_style">Shiny</button> */}
        </div>
    </>
  )
}

export default ResourcesSubBanner