import React from 'react'
import BannerInner from '../BannerInner/BannerInner'
import CssCourseSideNav from './CssCourseLayout/CssCourseSideNav'
import CodeHighlightComp from '../Pulgins/CodeHighlight/CodeHighlightComp'
import OurCourses from '../HomePage/OurCourses'

const WhatsCss = () => {
    return (
        <>
            <>
                <BannerInner
                    title="All You Need to Know About CSS Style Sheets"
                    banner_desc="CSS is an essential part of web development and design, enabling developers to control the visual presentation of web content and create aesthetically pleasing and user-friendly websites."
                />
                <section className='comman_tb_padding'>
                    <div className='container'>
                        <div className='row responsive_row'>
                            <div className='col-lg-3 col-md-4 sideNavbar_comman_main'>
                                <div className='sideNavbar_comman'>
                                    <CssCourseSideNav />
                                </div>
                            </div>
                            <div className='col-lg-9 col-md-8 content_style_main'>
                                <div className='content_style_comman'>
                                    <h3>What is CSS</h3>
                                    <p>CSS, or Cascading Style Sheets, is a vital technology in the world of web development and design. It serves as the creative powerhouse behind how web pages appear and feel. Let's delve into the details of CSS, while keeping things SEO-friendly.</p>
                                    <ul>
                                        <li><strong>1. Visual Control:</strong> CSS is the design wizard of the web. It determines how HTML elements are presented, including layout, colors, typography, spacing, and more. In essence, it transforms the raw structure of a webpage into a visually appealing and user-friendly experience.</li>
                                        <p><strong>2. Separation of Content and Style:</strong> One of CSS's superpowers is its ability to separate content (HTML) from presentation (styling). This separation simplifies the maintenance of websites because changes to appearance can be made globally through CSS rules without altering the underlying HTML content.</p>
                                        <li><strong>3. Selectors and Rules:</strong> CSS employs selectors to pinpoint HTML elements and apply styling rules to them. For example, you can style all headings (<code>&lt;h1&gt;</code>, <code>&lt;h2&gt;</code>, etc.) uniformly or customize specific elements by using classes or IDs. Rules dictate how these elements should look and behave.</li>
                                        <li><strong>4. Cascading Nature:</strong> The term "cascading" in CSS refers to the order of importance when resolving styling conflicts. Different CSS rules may compete for control, but CSS has a defined hierarchy to ensure the right styles take precedence.</li>
                                        <li><strong>5. Property-Value Pairs:</strong> CSS rules are formed by combining properties (e.g., <code>font-size</code>, <code>background-color</code>) with values (e.g., <code>16px</code>, <code>#FF5733</code>). Properties define what aspect of an element is being styled, while values set the specifics of that style.</li>
                                        <li><strong>6. Responsive Design:</strong> CSS is the driving force behind responsive web design. With media queries, it adapts web layouts to various screen sizes and devices. This ensures that websites look and function flawlessly on desktops, tablets, and mobile phones.</li>
                                        <li><strong>7. Modularity and Reusability:</strong> CSS encourages modularity and reusability. Developers can create CSS classes and IDs that can be applied consistently across a website, maintaining a cohesive design language.</li>
                                    </ul>
                                    

                                </div>
                                {/*  */}
                            </div>
                        </div>
                    </div>
                </section>
                {/*  */}
                <OurCourses />
            </>
        </>
    )
}

export default WhatsCss