import React from 'react'
import { Link } from 'react-router-dom'

const OurCourses = () => {
  return (
    <>
        <section className='site_features_sec bg_animation1'>
        <div className='container'>
          <div className='site_features_area '>
            {/* <div className='heading_box'>
              <h2>Site Features</h2>
            </div> */}
            <div className='comman_top-'>
              <div className='row'>
                <div className="col-lg-3 col-md-6 card_over_vidget_main">
                  <div className="card_over_vidget">
                    <div className="grid">
                      <figure className="effect-lily ">
                        <div className='card_img'>
                          <img src="/images/html_course_webpatches.jpg" alt="" />
                        </div>
                        <figcaption>
                          <div>
                            <h2>HTML</h2>
                          </div>
                          <Link to="/html/html-content-editable-attribute">View more</Link>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 card_over_vidget_main">
                  <div className="card_over_vidget">
                    <div className="grid">
                      <figure className="effect-lily ">
                        <div className='card_img'>
                          <img src="/images/css_course_webpatches.jpg" alt="" />
                        </div>
                        <figcaption>
                          <div>
                            <h2>CSS</h2>
                          </div>
                          <Link to="/css/center-element-both-side">View more</Link>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 card_over_vidget_main">
                  <div className="card_over_vidget">
                    <div className="grid">
                      <figure className="effect-lily ">
                        <div className='card_img'>
                          <img src="/images/reactjs_course_webpatches.jpg" alt="" />
                        </div>
                        <figcaption>
                          <div>
                            <h2>ReactJs</h2>
                          </div>
                          <Link to="/reactjs/what-is-reactjs">View more</Link>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 card_over_vidget_main">
                  <div className="card_over_vidget">
                    <div className="grid">
                      <figure className="effect-lily ">
                        <div className='card_img'>
                          <img src="/images/jQuery_course_webpatches.jpg" alt="" />
                        </div>
                        <figcaption>
                          <div>
                            <h2>JQuery</h2>
                          </div>
                          <Link to="">View more</Link>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OurCourses