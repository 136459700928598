import React from 'react'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom'

const NotFound = () => {
    const scriptContent = `
    console.log('This is an inline script');
    $(document).ready(function(){
        $("#hide").click(function(){
          $("p").hide();
        });
        $("#show").click(function(){
          $("p").show();
        });
      });

  `;

    return (
        <>
            <Helmet>
                {/* <title>WebPatches offers a curated collection of tools for web design and development, along with solutions tailored to development needs.  </title>
                <meta name="description" content="Website page speed is a critical factor that can significantly impact user experience and search engine rankings..." />
                <meta name="Keywords" content="how to increase page speed in html, how to increase page speed in PHP, how to speed up web page loading time in html" />
                <link rel="canonical" href="https://www.weblession.com/boost-your-website-page-speed-a-comprehensive-guide" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/boost-your-website-page-speed-a-comprehensive-guide" />
                <meta name="og:title" property="og:title" content="Boost Your Website Page Speed: A Comprehensive Guide" />
                <meta name="og:description" property="og:description" content="Website page speed is a critical factor that can significantly impact user experience and search engine rankings..." />
                <meta name="og:image" property="og:image" content="images/latest_html.webp" />
                <meta name="twitter:card" content="summary" /> */}
                <style>{"body { padding: 0 !important; } .nav_sec, .footer_sec, .footer_copyright_sec {display: none !important;}"}</style>

                
                
                

            </Helmet>
            <section className='error_page_sec'>
            <p>If you click on the "Hide" button, I will disappear.</p>

<button id="hide">Hide</button>
<button id="show">Show</button>
            <div dangerouslySetInnerHTML={{ __html: scriptContent }} />
                <div className='container'>
                    <div className='row error_page_area'>
                        <div className='col-lg-6 d-flex align-items-center'>
                            <div className='w-100'>
                                <h2>ERROR !</h2>
                                <h3>Page not found</h3>
                                <p className="mt-2">The page you are looking for doesn’t exist or an
                                    other error occurred,</p>
                                <Link className="shiny_btn_style" to="/">Back to Home</Link>
                            </div>
                        </div>
                        <div className='col-lg-6 error_page_right'>
                            <img src='/images/error_404.jpg' className='w-100' />
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className='error_page_sec'>
                <div className='container text-center'>
                    <h1>404 - Page Not Found</h1>
                    <p>Sorry, the page you are looking for does not exist.</p>
                    <Link className="" to="/">Back to Home</Link>
                </div>
            </section> */}
        </>
    )
}

export default NotFound